<template>
  <transition
    name="fade">
    <div
      :class="open ? 'modal modal-mask' : 'modal-mask'">
      <div
        class="modal-wrapper"
        :class="open ? 'slide': 'slide'">
        <div
          class="modal-dialog modal-dialog-centered"
          role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="$emit('close')" />
            </div>
            <div class="modal-body d-flex flex-column text-center">
              <div class="box-icon-check">
                <svg
                  width="54"
                  height="53"
                  viewBox="0 0 54 53"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M27 0.25C12.51 0.25 0.75 12.01 0.75 26.5C0.75 40.99 12.51 52.75 27 52.75C41.49 52.75 53.25 40.99 53.25 26.5C53.25 12.01 41.49 0.25 27 0.25ZM21.75 39.625L8.625 26.5L12.3263 22.7987L21.75 32.1963L41.6738 12.2725L45.375 16L21.75 39.625Z"
                    fill="#269926" />
                </svg>
              </div>
              <h5
                class="modal-title">
                CONFIRMAR
              </h5>
              <h3>
                <span v-if="estadoEditar">
                  ¿Estas seguro de guardar la información?
                </span>
                <span v-else>
                  ¿Estas seguro de habilitar?
                </span>
              </h3>
            </div>
            <div class="modal-footer justify-content-center">
              <div v-if="!estadoEditar">
                <button
                  type="button"
                  class="btn btn-lg btn-primary"
                  @click="$emit('confirmar')">
                  SI
                </button>
              </div>
              <div v-else>
                <button
                  type="button"
                  class="btn btn-lg btn-primary"
                  @click="$emit('editar')">
                  SI
                </button>
              </div>
              <button
                type="button"
                class="btn btn-lg btn-warning"
                @click="$emit('close')">
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ModalPregunta',
  props: {
    open: Boolean,
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapState([
      'loading',
      'consultaUsuario',
      'estadoEditar',
    ]),
  },
  methods: {
    gotoHome() {
      this.$router.push('/');
    },
  },
};
</script>

<style scoped lang="scss">
.modal-body {
    max-width: 500px;
    margin: 0 auto;
}
</style>
