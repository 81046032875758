// eslint-disable-next-line import/prefer-default-export
export const myListado = [{
  tipo_categoria: 'Quinta',
  fecha_de_pago: '03/08/2015',
  fecha_de_emision: '03/08/2015',
  tipo_de_contrato_o_comprobante: 'A PLAZO INDETERMINADO - D LEG 728',
  numero_de_contrato_o_comprobante: 'E12-000423',
  ocupacion: 'OCUPACION NO ESPECIFICADA',
  categoria_ocupacional: 'EMPLEADO',
  situacion_especial: 'NINGUNA',
  periodo_tributario: '202401',
  periodo_inicio: '03/08/2015',
  periodo_fin: '31/12/2017',
  razon_social: 'ASOCIACION DE BANCOS DEL PERU',
  ruc: '20100123330',
  ingreso: [
    {
      concepto: '0107 - TRABAJO EN DIA DE FERIADO O DIA DE DESCANSO',
      monto_de_pago: 316.67,
    },
    {
      concepto: '0121 - REMUNERACION O JORNAL BASICO',
      monto_de_pago: 2300.00,
    },
    {
      concepto: '0201 - ASIGNACION FAMILIAR',
      monto_de_pago: 75.00,
    },
  ],
  descuentos: [{
    concepto: '0701 - ADELANTADO',
    monto_de_pago: 0,
  }],
  aportaciones_trabajador: [{
    concepto: '0601 - SISTEMA PRIVADO DE PENSIONES COMISION PORCENTUAL',
    monto_de_pago: 45.49,
  }, {
    concepto: '0605 - RENTA QUINTA CATEGORÍA RETENCIONES',
    monto_de_pago: 73.67,
  }],
  aportaciones_empleador: [{
    concepto: '0804 - ESSALUD',
    monto_de_pago: 242.25,
  }],
  otros_conceptos: [{
    concepto: '-',
    monto_de_pago: '-',
  }],
  regimen_publico: [{
    concepto: '-',
    monto_de_pago: '-',
  }],
}, {
  tipo_categoria: 'Quinta',
  fecha_de_pago: '03/08/2015',
  fecha_de_emision: '03/08/2015',
  tipo_de_contrato_o_comprobante: 'A PLAZO INDETERMINADO - D LEG 728',
  numero_de_contrato_o_comprobante: 'E12-000426',
  ocupacion: 'OCUPACION NO ESPECIFICADA',
  categoria_ocupacional: 'EMPLEADO',
  situacion_especial: 'NINGUNA',
  periodo_tributario: '202403',
  periodo_inicio: '03/08/2015',
  periodo_fin: '31/12/2017',
  razon_social: 'ASOCIACION DE BANCOS DEL PERU',
  ruc: '20100123330',
  ingreso: [
    {
      concepto: '0107 - TRABAJO EN DIA DE FERIADO O DIA DE DESCANSO',
      monto_de_pago: 816.67,
    },
    {
      concepto: '0121 - REMUNERACION O JORNAL BASICO',
      monto_de_pago: 1300.00,
    },
    {
      concepto: '0201 - ASIGNACION FAMILIAR',
      monto_de_pago: 105.00,
    },
  ],
  descuentos: [{
    concepto: '0701 - ADELANTADO',
    monto_de_pago: 0,
  }],
  aportacones: [{
    concepto: '0601 - SISTEMA PRIVADO DE PENSIONES COMISION PORCENTUAL',
    monto_de_pago: 15.49,
  }, {
    concepto: '0605 - RENTA QUINTA CATEGORÍA RETENCIONES',
    monto_de_pago: 93.67,
  }],
  aportaciones_empleador: [{
    concepto: '0804 - ESSALUD',
    monto_de_pago: 242.25,
  }],
  otros_conceptos: [{
    concepto: '-',
    monto_de_pago: '-',
  }],
  regimen_publico: [{
    concepto: '-',
    monto_de_pago: '-',
  }],
}];
