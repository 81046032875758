<template>
  <div>
    <div
      v-if="loading"
      class="invex-loader">
      <div class="invex-loader_spinner" />
    </div>
    <header-main />
    <sidebar-main />
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid p-3">
          <!-- <p>
            consultaUsuario {{ consultaUsuario }}
          </p> -->
          <p class="d-none">
            estadoEditar {{ estadoEditar }}
          </p>
          <p class="d-none">
            usuarioSeleccionado {{ usuarioSeleccionado }}
          </p>
          <p class="d-none">
            usuarioSeleccionado2 {{ usuarioSeleccionado2 }}
          </p>
          <p class="d-none">
            listaDocumentos {{ listaDocumentos }}
          </p>
          <!-- <p>infoUsuario {{ infoUsuario }}</p> -->
          <!-- <p>selectedDocumento {{ selectedDocumento }}</p> -->
          <!-- <p>selectedPerfil {{ selectedPerfil }}</p> -->
          <!-- <p>listaPerfil {{ listaPerfil }}</p> -->
          <!-- <p>listaRegion {{ listaRegion }}</p> -->
          <!-- <p>listaZona {{ listaZona }}</p> -->
          <!-- <p>listaInstitucion {{ listaInstitucion }}</p> -->
          <!-- <p>selectedRegion {{ selectedRegion }}</p>
          <p>selectedInstitucion {{ selectedInstitucion }}</p>
          <p>operacionSeleccionada {{ operacionSeleccionada }}</p> -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <div class="box-title-usuario">
                  <h2 class="mb-sm-0">
                    Mis Usuarios
                  </h2>
                  <h4 class="mb-sm-0 font-size-18">
                    <span v-if="!estadoEditar">
                      Registrar Usuarios
                    </span>
                    <span v-else>
                      Editar Usuarios
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-xl-9">
              <form @submit.prevent="modalConfirmacion">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label
                        for="selectTipoDocumento"
                        class="form-label">Tipo de documento</label>
                      <select
                        id="selectTipoDocumento"
                        v-model="selectedDocumento"
                        class="form-select"
                        :class="{'is-invalid': submitted && v$.selectedDocumento.$error }">
                        <option value="">
                          --Seleccionar--
                        </option>
                        <option
                          v-for="item in listaDocumentos"
                          :key="item.codigo"
                          :value="item.codigo">
                          {{ item.descripcion }}
                        </option>
                      </select>
                      <div
                        v-if="submitted && v$.selectedDocumento.$error"
                        class="invalid-feedback">
                        <span v-if="v$.selectedDocumento.$error">{{ v$.selectedDocumento.$errors[0].$message }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label
                        for="inputDocumento"
                        class="form-label">Número de documento</label>
                      <div class="input-group">
                        <input
                          id="inputDocumento"
                          v-model="numero_documento"
                          type="text"
                          class="form-control"
                          mixlength="8"
                          maxlength="8"
                          placeholder="Ingrese número de documento"
                          @input="filterNumDoc">
                        <button
                          id="btnBusqueda"
                          type="button"
                          class="btn btn-outline-secondary"
                          :disabled="!isDocumentoValid">
                          <i
                            class="mdi mdi-account-search"
                            @click="buscarDocumento" />
                        </button>
                        <button
                          type="button"
                          class="btn btn-outline-secondary">
                          <i
                            class="mdi mdi-eraser"
                            @click="borrarDatos" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label
                        for="inputNombres"
                        class="form-label">Nombres <span class="obligatorio">(*)</span></label>
                      <input
                        id="inputNombres"
                        v-model="nombreso"
                        type="text"
                        class="form-control"
                        disabled
                        placeholder="Ingrese Nombres">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label
                        for="inputPaterno"
                        class="form-label">Apellido Paterno <span class="obligatorio">(*)</span></label>
                      <input
                        id="inputPaterno"
                        v-model="paterno"
                        type="text"
                        class="form-control"
                        disabled
                        placeholder="Ingrese Apellido Paterno">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label
                        for="inputMaterno"
                        class="form-label">Apellido Materno <span class="obligatorio">(*)</span></label>
                      <input
                        id="inputMaterno"
                        v-model="materno"
                        type="text"
                        class="form-control"
                        disabled
                        placeholder="Ingrese Apellido Materno">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label
                        for="inputCelular"
                        class="form-label">Celular</label>
                      <input
                        id="inputCelular"
                        v-model="celular"
                        type="text"
                        maxlength="9"
                        class="form-control"
                        placeholder="Celular"
                        @input="filterNumCelular">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label
                        for="inputEmail"
                        class="form-label">Email <span class="obligatorio">(*)</span></label>
                      <input
                        id="inputEmail"
                        v-model="email"
                        type="email"
                        class="form-control"
                        :class="{'is-invalid': submitted && v$.email.$error }"
                        placeholder="Ingrese Email">
                      <div
                        v-if="submitted && v$.email.$error"
                        class="invalid-feedback">
                        <span v-if="v$.email.$error">{{ v$.email.$errors[0].$message }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label
                        for="selectRegion"
                        class="form-label">Región</label>
                      <select
                        v-model="selectedRegion"
                        class="form-select"
                        :class="{'is-invalid': submitted && v$.selectedRegion.$error }">
                        <option value="">
                          --Seleccionar--
                        </option>
                        <option
                          v-for="item in listaRegion"
                          :key="item.codigo_region"
                          :value="item.codigo_region">
                          {{ item.nombre_region }}
                        </option>
                      </select>
                      <div
                        v-if="submitted && v$.selectedRegion.$error"
                        class="invalid-feedback">
                        <span v-if="v$.selectedRegion.$error">{{ v$.selectedRegion.$errors[0].$message }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label
                        for="selectInstitucion"
                        class="form-label">Institución</label>
                      <select
                        v-model="selectedInstitucion"
                        class="form-select"
                        :class="{'is-invalid': submitted && v$.selectedInstitucion.$error }">
                        <option value="">
                          --Seleccionar--
                        </option>
                        <option
                          v-for="item in listaInstitucion2"
                          :key="item.codpli"
                          :value="item.codpli">
                          {{ item.despli }}
                        </option>
                      </select>
                      <div
                        v-if="submitted && v$.selectedInstitucion.$error"
                        class="invalid-feedback">
                        <span v-if="v$.selectedInstitucion.$error">
                          {{ v$.selectedInstitucion.$errors[0].$message }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label
                        for="selectPerfil"
                        class="form-label">Perfil</label>
                      <select
                        v-model="selectedPerfil"
                        class="form-select"
                        :class="{'is-invalid': submitted && v$.selectedPerfil.$error }">
                        <option value="">
                          --Seleccionar--
                        </option>
                        <option
                          v-for="item in listaPerfil"
                          :key="item.pk_grupo"
                          :value="item.pk_grupo">
                          {{ item.nombre }}
                        </option>
                      </select>
                      <div
                        v-if="submitted && v$.selectedPerfil.$error"
                        class="invalid-feedback">
                        <span v-if="v$.selectedPerfil.$error">{{ v$.selectedPerfil.$errors[0].$message }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label
                        for="selectInstitucion"
                        class="form-label">Cargo</label>
                      <select
                        v-model="selectedCargo"
                        class="form-select"
                        :class="{'is-invalid': submitted && v$.selectedCargo.$error }">
                        <option value="">
                          --Seleccionar--
                        </option>
                        <option
                          v-for="item in listaCargo"
                          :key="item.codigo"
                          :value="item.codigo">
                          {{ item.desc_cargo }}
                        </option>
                      </select>
                      <div
                        v-if="submitted && v$.selectedCargo.$error"
                        class="invalid-feedback">
                        <span v-if="v$.selectedCargo.$error">{{ v$.selectedCargo.$errors[0].$message }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <div class="form-check">
                    <input
                      id="gridCheck"
                      v-model="isChecked"
                      class="form-check-input"
                      type="checkbox">
                    <label
                      class="form-check-label"
                      for="gridCheck">
                      Habilitar acceso con usuario y contraseña
                    </label>
                  </div>
                </div>
                <div class="hstack gap-3">
                  <button
                    class="btn btn-warning w-md"
                    @click="regresarPanel">
                    Regresar
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary w-md">
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <footer-main />
      <modal-pregunta
        v-if="showModalPregunta"
        :open="showModalPregunta"
        @confirmar="registrarUsuario"
        @editar="editarUsuario"
        @close="handleClosePregunta" />
      <modal-error
        v-if="showModalError"
        :open="showModalError"
        @close="handleCloseError" />
      <modal-custom
        v-if="modalCustom.open"
        :open="modalCustom.open"
        :type="modalCustom.type"
        :title="modalCustom.title"
        :message="modalCustom.message"
        @confirmar="registrarUsuario"
        @editar="editarUsuario"
        @close="handleCloseModal" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { required, email, helpers } from '@vuelidate/validators';
// eslint-disable-next-line no-unused-vars
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/extensions, import/no-unresolved
import ApiService from '@/apiservices';
// eslint-disable-next-line import/no-unresolved
import ModalError from '@/components/ModalError.vue';
// eslint-disable-next-line import/no-unresolved
import ModalPregunta from '@/components/ModalPregunta.vue';
// eslint-disable-next-line import/no-unresolved
import HeaderMain from '@/components/HeaderMain.vue';
// eslint-disable-next-line import/no-unresolved
import SidebarMain from '@/components/SidebarMain.vue';
// eslint-disable-next-line import/no-unresolved
import FooterMain from '@/components/FooterMain.vue';
// eslint-disable-next-line import/no-unresolved
import ModalCustom from '@/components/ModalCustom.vue';

// Lista de dominios prohibidos
const blockedDomains = [
  'gmail.com',
  'hotmail.com',
  'yahoo.com',
  'icloud.com',
  'aol.com',
  'protonmail.com',
  'gmx.com',
  'yandex.com',
  'mail.com',
];

// Expresión regular que bloquea los dominios prohibidos
const noBlockedDomains = helpers.withMessage(
  'Use un correo institucional (no se aceptan @gmail.com, @outlook.com, etc.)',
  helpers.regex(new RegExp(`^[^@]+@(?!(${blockedDomains.join('|')})$)[^@]+.[^@]+$`)),
);

export default {
  name: 'UsuarioView',
  components: {
    HeaderMain,
    SidebarMain,
    FooterMain,
    ModalError,
    ModalPregunta,
    ModalCustom,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    selectedDocumento: {
      required: helpers.withMessage('Tipo documento es requerido', required),
    },
    selectedRegion: {
      required: helpers.withMessage('Combo región es requerido', required),
    },
    selectedInstitucion: {
      required: helpers.withMessage('Combo Insitución es requerido', required),
    },
    selectedPerfil: {
      required: helpers.withMessage('Combo Perfil es requerido', required),
    },
    selectedCargo: {
      required: helpers.withMessage('Combo Cargo es requerido', required),
    },
    email: {
      required: helpers.withMessage('Correo electrónico es requerido', required),
      email: helpers.withMessage('Debe ser un correo electrónico válido', email),
      noBlockedDomains,
    },
  },
  data() {
    return {
      username: '',
      numero_documento: '',
      q_persona: '',
      showModalError: false,
      showModalPregunta: false,
      listadoUsuarios: [],
      listaDocumentos: [],
      listaRegion: [],
      listaZona: [],
      listaPerfil: [],
      listaInstitucion: [],
      listaInstitucion2: [],
      selectedDocumento: '03',
      selectedRegion: '',
      codigo_region: '',
      selectedPerfil: '',
      selectedInstitucion: '',
      selectedCargo: '',
      infoUsuario: '',
      celular: '',
      email: '',
      nombres: '',
      apPaterno: '',
      appMaterno: '',
      datosNuevo: '',
      isChecked: false,
      submitted: false,
      usuarioSeleccionado2: {
        pk_usuario: '41826439',
        email: 'jorge@gmail.com',
        tipo_documento_str: 'DNI',
        nombres: 'MAXIMA',
        apellido_paterno: 'AGUIRRE',
        apellido_materno: 'LEANDRO',
        codigo_cargo: '06',
        pk_grupo: 1,
        nombre_escala: 'SIN ESCALA',
        nombre_grupo: 'Administrador',
        tipo_documento: '03',
        username: 'MAGUIRRE',
        flag: '0',
        celular: '920830112',
        compartir_datos: '1',
        terminos_condiciones: '0',
        tipo_usuario: '1',
        super_admin: '0',
        codigo_region: '14',
        codigo_institucion: '4',
      },
      modalCustom: {
        open: false,
        title: '',
        message: '',
        type: 'success',
        customHtml: '',
      },
    };
  },
  computed: {
    ...mapState([
      'loading',
      'consultaUsuario',
      'usuarioSeleccionado',
      'estadoEditar',
    ]),
    nombreso() {
      if (this.infoUsuario) {
        return this.infoUsuario.nombres;
      }
      return this.nombres;
    },
    paterno() {
      if (this.infoUsuario) {
        return this.infoUsuario.apellido_paterno;
      }
      return this.apPaterno;
    },
    materno() {
      if (this.infoUsuario) {
        return this.infoUsuario.apellido_materno;
      }
      return this.appMaterno;
    },
    isDocumentoValid() {
      return this.selectedDocumento !== '' && this.numero_documento.length === 8;
    },
  },
  mounted() {
    this.listaTipoDocumento();
    this.listadoRegion();
    this.listadoPerfil();
    if (this.estadoEditar) {
      this.cargaDatoUsuario();
    }
    this.listadoInstitucion();
    this.listadoCargo();
  },
  methods: {
    async listadoCargo() {
      try {
        this.$store.state.loading = true;
        const body = {
          codigo: '03',
          desc_cargo: '43033349',
        };
        const response = await ApiService.getListarCargo(body);
        if (response.status === 200 || response.status === 208) {
          this.listaCargo = response.data;
          this.$store.state.loading = false;
        } else if (response.status === 500) {
          this.$store.state.loading = false;
        } else {
          // eslint-disable-next-line no-alert
          // Manejar otros códigos de estado si es necesario
        }
      } catch (error) {
        this.$store.state.loading = false;
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
    filterNumCelular(event) {
      this.celular = event.target.value.replace(/[^0-9]/g, '');
    },
    filterNumDoc(event) {
      this.numero_documento = event.target.value.replace(/[^0-9]/g, '');
    },
    cargaDatoUsuario() {
      this.selectedDocumento = this.usuarioSeleccionado.tipo_documento || '03';
      this.numero_documento = this.usuarioSeleccionado.pk_usuario;
      this.celular = this.usuarioSeleccionado.celular;
      this.nombres = this.usuarioSeleccionado.nombres;
      this.apPaterno = this.usuarioSeleccionado.apellido_paterno;
      this.appMaterno = this.usuarioSeleccionado.apellido_materno;
      this.email = this.usuarioSeleccionado.email;
      this.selectedRegion = this.usuarioSeleccionado.codigo_region;
      this.selectedPerfil = this.usuarioSeleccionado.pk_grupo;
      this.selectedInstitucion = this.usuarioSeleccionado.codigo_institucion;
      this.selectedCargo = this.usuarioSeleccionado.codigo_cargo;
      if (this.usuarioSeleccionado.compartir_datos === '1') {
        this.isChecked = true;
      } else {
        this.isChecked = false;
      }
      document.getElementById('selectTipoDocumento').disabled = 'disabled';
      document.getElementById('inputDocumento').disabled = 'disabled';
      document.getElementById('btnBusqueda').disabled = 'disabled';
    },
    handleCloseError() {
      this.showModalError = false;
    },
    handleClosePregunta() {
      this.showModalPregunta = false;
    },
    async listarUsuarios() {
      try {
        this.$store.state.loading = true;
        const response = await ApiService.getListarUsuarios();
        if (response.status === 200 || response.status === 208) {
          this.$store.state.loading = false;
          this.listadoUsuarios = response.data.results;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            this.$store.state.loading = false;
            this.showModalError = true;
            if (error.response.data.errors) {
              this.$store.state.loading = false;
              this.showModalError = true;
              this.errores = error.response.data.errors;
            }
          }
        } else if (error.request) {
          this.$store.state.loading = false;
          this.showModalError = true;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      }
    },
    async buscarUsuarios() {
      try {
        const params = {};
        this.$store.state.loading = true;
        if (this.username) {
          params.username = this.username;
        }
        if (this.numero_documento) {
          params.numero_documento = this.numero_documento;
        }
        if (this.q_persona) {
          params.q_persona = this.q_persona;
        }
        const response = await ApiService.getListarUsuarios(params);
        // const response = await ApiService.getListarUsuarios({ username: this.username });
        // const response = await ApiService.getListarUsuarios({ params: { username: this.username } });
        if (response.status === 200 || response.status === 208) {
          this.$store.state.loading = false;
          this.listadoUsuarios = response.data.results;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            this.$store.state.loading = false;
            this.showModalError = true;
            if (error.response.data.errors) {
              this.$store.state.loading = false;
              this.showModalError = true;
              this.errores = error.response.data.errors;
            }
          }
        } else if (error.request) {
          this.$store.state.loading = false;
          this.showModalError = true;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      }
    },
    async editarUsuario() {
      try {
        const body = {
          pk_usuario: this.numero_documento,
          username: this.numero_documento,
          tipo_documento: this.selectedDocumento,
          email: this.email,
          pk_grupo: this.selectedPerfil,
          codigo_region: this.selectedRegion,
          codigo_institucion: this.selectedInstitucion.trim(),
          codigo_cargo: this.selectedCargo,
          compartir_datos: this.isChecked ? '1' : '0',
        };
        // Validación del celular
        if (this.celular && this.celular.trim() !== '') {
          body.celular = this.celular;
        }
        const response = await ApiService.getEditarUsuarios(body);
        if (response.status === 200 || response.status === 208) {
          this.$store.state.loading = false;
          this.$store.dispatch('updateEstadoEditar', false);
          this.$router.push('/panel');
          this.listarUsuarios();
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            this.$store.state.loading = false;
            this.showModalError = true;
            if (error.response.data.errors) {
              this.$store.state.loading = false;
              this.showModalError = true;
              this.errores = error.response.data.errors;
            }
          }
        } else if (error.request) {
          this.$store.state.loading = false;
          this.showModalError = true;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      }
    },
    async listaTipoDocumento() {
      try {
        this.$store.state.loading = true;
        const response = await ApiService.getListarTipoDocumento();
        // eslint-disable-next-line
        const filterDocumento = response.data.filter((item) => item.codigo == '03');
        this.listaDocumentos = filterDocumento;
        // this.listaDocumentos = response.data;
        if (response.status === 200 || response.status === 208) {
          this.$store.dispatch('updateOperacionSeleccionada', 'Hola');
          this.$store.state.loading = false;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            this.$store.state.loading = false;
            this.showModalError = true;
            if (error.response.data.errors) {
              this.$store.state.loading = false;
              this.showModalError = true;
              this.errores = error.response.data.errors;
            }
          }
        } else if (error.request) {
          this.$store.state.loading = false;
          this.showModalError = true;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      }
    },
    async listadoInstitucion() {
      try {
        this.$store.state.loading = true;
        const response = await ApiService.getListarInstitucion();
        // eslint-disable-next-line
        this.listaInstitucion = response.data;
        // eslint-disable-next-line no-unused-vars
        this.listaInstitucion2 = response.data.map((item) => ({
          ...item,
          codpli: item.codpli.trim(),
        }));
        if (response.status === 200 || response.status === 208) {
          this.$store.state.loading = false;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            this.$store.state.loading = false;
            this.showModalError = true;
            if (error.response.data.errors) {
              this.$store.state.loading = false;
              this.showModalError = true;
              this.errores = error.response.data.errors;
            }
          }
        } else if (error.request) {
          this.$store.state.loading = false;
          this.showModalError = true;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      }
    },
    async listadoRegion() {
      try {
        this.$store.state.loading = true;
        const response = await ApiService.getListarRegion();
        this.listaRegion = response.data;
        if (response.status === 200 || response.status === 208) {
          this.$store.state.loading = false;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            this.$store.state.loading = false;
            this.showModalError = true;
            if (error.response.data.errors) {
              this.$store.state.loading = false;
              this.showModalError = true;
              this.errores = error.response.data.errors;
            }
          }
        } else if (error.request) {
          this.$store.state.loading = false;
          this.showModalError = true;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      }
    },
    async listadoZona() {
      try {
        this.$store.state.loading = true;
        const response = await ApiService.getListarZona(this.codigo_region);
        if (response.status === 200 || response.status === 208) {
          this.$store.state.loading = false;
          this.listaZona = response.data;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            this.$store.state.loading = false;
            this.showModalError = true;
            if (error.response.data.errors) {
              this.$store.state.loading = false;
              this.showModalError = true;
              this.errores = error.response.data.errors;
            }
          }
        } else if (error.request) {
          this.$store.state.loading = false;
          this.showModalError = true;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      }
    },
    /* fnSeleccionarRegion(e) {
      if (e.target.value !== '') {
        this.codigo_region = e.target.value;
        this.listadoZona(this.codigo_region);
      }
    }, */
    async listadoPerfil() {
      try {
        this.$store.state.loading = true;
        const response = await ApiService.getListarPerfil();
        this.listaPerfil = response.data;
        if (response.status === 200 || response.status === 208) {
          this.$store.state.loading = false;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            this.$store.state.loading = false;
            this.showModalError = true;
            if (error.response.data.errors) {
              this.$store.state.loading = false;
              this.showModalError = true;
              this.errores = error.response.data.errors;
            }
          }
        } else if (error.request) {
          this.$store.state.loading = false;
          this.showModalError = true;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      }
    },
    async buscarDocumento() {
      try {
        this.$store.state.loading = true;
        const body = {
          tipo_documento: this.selectedDocumento,
          numero_documento: this.numero_documento,
          datos: '1',
          easy_data: '1',
        };
        const response = await ApiService.getBuscarDocumento(body);
        // eslint-disable-next-line
        if (response.status === 200 || response.status === 208) {
          this.infoUsuario = response.data.datos;
          this.$store.state.loading = false;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            this.$store.state.loading = false;
            this.showModalError = true;
            if (error.response.data.errors) {
              this.$store.state.loading = false;
              this.showModalError = true;
              this.errores = error.response.data.errors;
            }
          }
        } else if (error.request) {
          this.$store.state.loading = false;
          this.showModalError = true;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      }
    },
    async registrarUsuario() {
      try {
        this.$store.state.loading = true;
        const body = {
          pk_usuario: '',
          username: this.numero_documento,
          tipo_documento: this.selectedDocumento,
          email: this.email,
          pk_grupo: this.selectedPerfil,
          codigo_dependencia: 241,
          celular: this.celular,
          admin_creation: '1',
          tipo_usuario: '1',
          usuario_interno: '0',
          auto_password: '1',
          codigo_region: this.selectedRegion,
          codigo_institucion: this.selectedInstitucion.trim(),
          codigo_cargo: this.selectedCargo,
          compartir_datos: this.isChecked ? '1' : '0',
        };
        const response = await ApiService.postCrearUsuario(body);
        if (response.status === 200 || response.status === 208) {
          this.$store.state.loading = false;
          this.datosNuevo = response.data.token_cache;
          localStorage.setItem('tokenCacheNuevo', this.datosNuevo);
          this.showModalPregunta = false;
          this.enviarCredenciales();
          this.$router.push('/panel');
        } else {
          this.$store.state.loading = false;
          this.showModalPregunta = false;
          this.showModalError = true;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400 || error.response.status === 406) {
            this.$store.state.loading = false;
            this.showModalError = true;
            this.showModalPregunta = false;
            if (error.response.data.errors) {
              this.$store.state.loading = false;
              this.showModalError = true;
              this.showModalPregunta = false;
              this.errores = error.response.data.errors;
            }
          }
        } else if (error.request) {
          this.$store.state.loading = false;
          this.showModalError = true;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      }
    },
    async enviarCredenciales() {
      const body = {
        tipo: '2',
      };
      try {
        this.$store.state.loading = true;
        const response = await ApiService.postCredenciales(body);
        if (response.status === 200 || response.status === 208) {
          this.$store.state.loading = false;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        this.respuesta = error.response ? error.response.data : 'Error de red';
      }
    },
    async modalConfirmacion() {
      this.submitted = true;
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        // this.showModalPregunta = true;
        this.modalCustom.open = true;
        this.modalCustom.type = 'success';
        this.modalCustom.title = 'CONFIRMAR';
        this.modalCustom.message = '<p>¿Estas seguro de guardar la información?.</p>';
      }
    },
    regresarPanel() {
      this.$store.dispatch('updateEstadoEditar', false);
      this.$store.dispatch('updateUsuarioSeleccionado', []);
      this.$router.push('/panel');
    },
    borrarDatos() {
      this.selectedDocumento = '';
      this.numero_documento = '';
    },
    handleCloseModal() {
      this.modalCustom.open = false;
    },
  },
};
</script>
