<template>
  <div
    id="content-wrapper"
    class="d-flex flex-column">
    <div
      v-if="loading"
      class="invex-loader">
      <div class="invex-loader_spinner" />
    </div>
    <div id="content">
      <header-component />
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="wrap-login">
              <div class="box-login bg-gray-light radius">
                <div class="circle">
                  <img src="@/assets/escudo.png">
                </div>
                <div
                  class="title mb-4">
                  Sistema de Gestión de Consultas de Planilla Electrónica
                </div>
                <p class="d-none">
                  tokenCache {{ tokenCache }}
                </p>
                <div class="box-form-login">
                  <div class="form-group">
                    <input
                      v-model="usuario"
                      type="text"
                      class="form-control"
                      placeholder="Ingrese Usuario"
                      :class="{'is-invalid': submitted && v$.usuario.$error }">
                    <div
                      v-if="submitted && v$.usuario.$error"
                      class="invalid-feedback">
                      <span v-if="v$.usuario.$error">{{ v$.usuario.$errors[0].$message }}</span>
                    </div>
                  </div>
                  <div class="form-group">
                    <button
                      type="button"
                      class="btn btn-block btn-warning"
                      @click="validaLogin">
                      Ingresar
                    </button>
                  </div>
                </div>
                <div class="box-descargas">
                  <a
                    href="#"
                    class="btn btn-danger btn-icon-split">
                    <span class="text">Descargar solicitud de acceso</span>
                  </a>
                  <a
                    href="#"
                    class="btn btn-danger btn-icon-split">
                    <span class="text">Descargar manual de acceso</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-component />
    <modal-error
      v-if="showModalError"
      :open="showModalError"
      :message="errorMessage"
      @close="handleCloseError" />
    <modal-custom
      v-if="modalCustom.open"
      :open="modalCustom.open"
      :type="modalCustom.type"
      :title="modalCustom.title"
      :message="modalCustom.message"
      @close="handleCloseModal" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import { required, email, minLength } from '@vuelidate/validators';
import { required, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/no-unresolved
import HeaderComponent from '@/components/HeaderComponent.vue';
// eslint-disable-next-line import/no-unresolved
import FooterComponent from '@/components/FooterComponent.vue';
// eslint-disable-next-line import/no-unresolved
import ModalError from '@/components/ModalError.vue';
// eslint-disable-next-line import/no-unresolved
import ModalCustom from '@/components/ModalCustom.vue';

const alfanumericoRegex = /^[a-zA-Z0-9]*$/;

const alfanumerico = helpers.withMessage(
  'Solo se permiten caracteres alfanuméricos',
  (value) => alfanumericoRegex.test(value),
);

export default {
  name: 'LogeoView',
  components: {
    HeaderComponent,
    FooterComponent,
    ModalError,
    ModalCustom,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    usuario: {
      required: helpers.withMessage('Usuario es requerido', required),
      alfanumerico,
    },
  },
  data() {
    return {
      usuario: '',
      respuesta: null,
      respuestaVerifica: null,
      showModalError: false,
      tipoVista: null,
      estado: null,
      nuevo: null,
      idPeru: null,
      tokenCache: null,
      nombre: '',
      nombreError: false,
      email: '',
      password: '',
      submitted: false,
      modalCustom: {
        open: false,
        title: '',
        message: '',
        type: 'success',
        customHtml: '',
      },
      errorMessage: '',
    };
  },
  computed: {
    ...mapState(['dobleFactor', 'loading']),
    ...mapState(['consultaUsuario']),
  },
  mounted() {
  },
  methods: {
    async validaLogin() {
      this.submitted = true;
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        const body = {
          username: this.usuario,
          tipo_usuario: '1',
          tipo_documento: '03',
          skip_password: '1',
          usuario_interno: '0',
        };
        try {
          this.$store.state.loading = true;
          const response = await this.$store.dispatch('updateUsuario', body);
          if (response.status === 200 || response.status === 208) {
            this.$store.state.loading = false;
            this.respuesta = response.data;
            this.tipoVista = response.data.compartir_datos; // 0 Facial , 1 2FA
            this.tokenCache = response.data.token_cache;
            this.estado = response.data.estado;
            this.nuevo = response.data.nuevo;
            this.idPeru = response.data.response_idperu;
            localStorage.setItem('tokenCache', this.tokenCache);
            localStorage.setItem('user_data', JSON.stringify(this.respuesta));
            if (this.tipoVista === '1') {
              this.$router.push('/clave');
            } else {
              window.location.href = this.idPeru;
            }
            // valida estado habilitado e inhabilitado
            /* if (this.estado === '1' && this.nuevo === false) {
              if (this.tipoVista === '1') {
                this.$router.push('/clave');
              } else {
                this.$router.push('/');
              }
            } else {
              this.$store.state.loading = false;
              this.modalCustom.open = true;
              this.modalCustom.type = 'warning';
              this.modalCustom.title = 'Usuario Inhabilitado';
              this.modalCustom.message = '<p>El Usuario ha sido inhabilitado.</p><p>Por favor intenta nuevamente.</p>';
            } */
          } else {
            this.$store.state.loading = false;
            this.showModalError = true;
            // eslint-disable-next-line no-undef
            this.errorMessage = error.response.data.detail || 'No se ha podido conectar con el servidor.';
            /* this.modalCustom.open = true;
            this.modalCustom.type = 'error';
            this.modalCustom.title = 'Error en su solicitud';
            this.modalCustom.message = '<p>Las credenciales no son correctas o no existen.</p>
            <p>Por favor intenta nuevamente.</p>'; */
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 403) {
              this.$store.state.loading = false;
              this.showModalError = true;
              this.errorMessage = error.response.data.detail || 'No tienes permisos para acceder a este recurso.';
            } else if (error.response.status === 400) {
              this.$store.state.loading = false;
              this.showModalError = true;
              if (error.response.data.errors) {
                this.$store.state.loading = false;
                this.showModalError = true;
                this.errorMessage = 'Error en la solicitud. Por favor revisa los datos.';
              }
            }
          } else if (error.request) {
            this.$store.state.loading = false;
            this.showModalError = true;
            this.errorMessage = 'No se ha podido conectar con el servidor.';
          } else {
            this.$store.state.loading = false;
            this.showModalError = true;
            this.errorMessage = 'Ha ocurrido un error desconocido.';
          }
        }
      } else {
        // Usuario inválido
      }
    },
    handleCloseError() {
      this.showModalError = false;
    },
    handleCloseModal() {
      this.modalCustom.open = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
