<template>
  <div>
    <div
      v-if="loading"
      class="invex-loader">
      <div class="invex-loader_spinner" />
    </div>
    <header-main />
    <sidebar-main />
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0 font-size-18">
                  Lista de Usuarios
                </h4>
              </div>
            </div>
          </div>
          <p class="d-none">
            listadoUsuarios {{ listadoUsuarios }}
          </p>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <form
                    class="repeater"
                    enctype="multipart/form-data">
                    <div data-repeater-list="group-a">
                      <div
                        data-repeater-item
                        class="row">
                        <div class="mb-3 col-12 col-md-4 col-lg-2">
                          <label for="searchDocumento">N° Documento</label>
                          <input
                            id="searchDocumento"
                            v-model="numero_documento"
                            type="text"
                            class="form-control"
                            placeholder="N° de documento">
                        </div>

                        <div class="mb-3 col-12 col-md-4 col-lg-2">
                          <label for="searchUsuario">Usuario</label>
                          <input
                            id="searchUsuario"
                            v-model="username"
                            type="text"
                            class="form-control"
                            placeholder="Usuario">
                        </div>

                        <div class="mb-3 col-12 col-md-4 col-lg-2">
                          <label for="searchNombres">Nombres y Apellidos</label>
                          <input
                            id="searchNombres"
                            v-model="q_persona"
                            type="text"
                            class="form-control"
                            placeholder="Nombre o Apellidio">
                        </div>

                        <div class="col-12 col-md-4 col-lg-2 align-self-lg-center">
                          <div class="d-flex justify-content-around">
                            <input
                              type="button"
                              class="btn btn-primary"
                              value="Buscar"
                              @click="buscarUsuarios">
                            <input
                              type="button"
                              class="btn btn-warning"
                              value="Limpiar"
                              @click="limpiarUsuarios">
                          </div>
                        </div>
                        <div class="mt-2 mt-md-0 col-12 col-md-4 col-lg-2 align-self-lg-center">
                          <div class="d-flex justify-content-around justify-content-lg-start">
                            <input
                              type="button"
                              class="btn btn-danger"
                              value="Agregar Usuaro"
                              @click="agregarUsuario">
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table align-middle table-nowrap table-check">
                      <thead class="table-light">
                        <tr>
                          <th class="align-middle">
                            Número
                          </th>
                          <th class="align-middle">
                            N° de Documento
                          </th>
                          <th class="align-middle">
                            Usuario
                          </th>
                          <th class="align-middle">
                            Nombre y Apellido
                          </th>
                          <th class="align-middle">
                            Perfil
                          </th>
                          <th class="align-middle">
                            Actiones
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(usuario, index) in usuariosFiltrados"
                          :key="usuario.pk_usuario">
                          <td>{{ index + 1 }}</td>
                          <td>{{ usuario.pk_usuario }}</td>
                          <td>{{ usuario.username }}</td>
                          <td>
                            {{
                              usuario.nombres +
                                " " +
                                usuario.apellido_paterno +
                                " " +
                                usuario.apellido_materno
                            }}
                          </td>
                          <td>{{ usuario.nombre_grupo }}</td>
                          <td>
                            <div class="d-flex gap-3 link-acciones">
                              <a
                                href="javascript:void(0);"
                                class="text-dark"
                                @click="editarUsuario2(usuario)"><i class="mdi mdi-pencil font-size-18" />
                                <span>Editar</span>
                              </a>
                              <a
                                href="javascript:void(0);"
                                class="text-dark"
                                :class="usuario.flag == 1 && usuario.pk_grupo !== 4 ? 'activate': ''"
                                @click="confirmarEliminar(usuario.pk_usuario)"><i class="mdi mdi-delete font-size-18" />
                                <span v-if="usuario.flag == 1 && usuario.pk_grupo !== 4">Inhabilitar</span>
                                <span v-else-if="usuario.flag == 0 && usuario.pk_grupo !== 4">Habilitar</span>
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <ul class="pagination pagination-rounded justify-content-end mb-2">
                    <li
                      class="page-item"
                      :class="{ disabled: currentPage === 1 }">
                      <a
                        aria-label="Previous"
                        class="page-link btn"
                        @click="goToPage(1)">
                        <i class="mdi mdi-chevron-left" />
                      </a>
                    </li>
                    <!--  <li
                      v-if="showLeftEllipsis"
                      class="page-item">
                      <a
                        class="page-link btn"
                        @click="goToPage(currentPage - 5)">
                        <span aria-hidden="true">...</span>
                      </a>
                    </li> -->
                    <li
                      v-for="page in visiblePages"
                      :key="page"
                      class="page-item"
                      :class="{ active: currentPage === page }">
                      <a
                        class="page-link btn"
                        @click="goToPage(page)">{{ page }}</a>
                    </li>
                    <!-- <li
                      v-if="showRightEllipsis"
                      class="page-item">
                      <a
                        class="page-link btn"
                        @click="goToPage(currentPage + 5)">
                        <span aria-hidden="true">...</span>
                      </a>
                    </li> -->
                    <li
                      class="page-item"
                      :class="{ disabled: currentPage === totalPages }">
                      <a
                        aria-label="Next"
                        class="page-link btn"
                        @click="goToPage(totalPages)">
                        <i class="mdi mdi-chevron-right" />
                      </a>
                    </li>
                  </ul>
                  <div class="box-pagination d-none">
                    <p>currentPage {{ currentPage }}</p>
                    <p>totalPages {{ totalPages }}</p>
                    <p>totalUsers {{ totalUsers }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer-main />
      <modal-custom
        v-if="modalCustom.open"
        :open="modalCustom.open"
        :type="modalCustom.type"
        :title="modalCustom.title"
        :message="modalCustom.message"
        @confirmar="eliminarUsuario"
        @close="handleCloseModal" />
      <modal-pregunta
        v-if="showModalPregunta"
        :open="showModalPregunta"
        :type="preguntaTipo"
        @confirmar="eliminarUsuario"
        @close="handleClosePregunta" />
      <modal-error
        v-if="showModalError"
        :open="showModalError"
        @close="handleCloseError" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// eslint-disable-next-line import/extensions, import/no-unresolved
// import axios from 'axios';
// eslint-disable-next-line import/extensions, import/no-unresolved
import ApiService from '@/apiservices';
// eslint-disable-next-line import/no-unresolved
import ModalError from '@/components/ModalError.vue';
// eslint-disable-next-line import/no-unresolved
import ModalPregunta from '@/components/ModalPregunta.vue';
// eslint-disable-next-line import/no-unresolved
import HeaderMain from '@/components/HeaderMain.vue';
// eslint-disable-next-line import/no-unresolved
import SidebarMain from '@/components/SidebarMain.vue';
// eslint-disable-next-line import/no-unresolved
import FooterMain from '@/components/FooterMain.vue';
// eslint-disable-next-line import/no-unresolved
import ModalCustom from '@/components/ModalCustom.vue';

export default {
  name: 'PanelView',
  components: {
    HeaderMain,
    SidebarMain,
    FooterMain,
    ModalError,
    ModalPregunta,
    ModalCustom,
  },
  data() {
    return {
      username: '',
      numero_documento: '',
      q_persona: '',
      showModalError: false,
      showModalPregunta: false,
      usuarioAEliminar: null,
      usuarioSelected: null,
      usuarioTipo: null,
      usuarioAEditar: null,
      year: new Date().getFullYear(),
      modalCustom: {
        open: false,
        title: '',
        message: '',
        type: 'success',
        customHtml: '',
      },
      // users: [],
      listadoUsuarios: [],
      totalUsers: 0,
      itemsPerPage: 10,
      currentPage: 1,
    };
  },
  computed: {
    ...mapState([
      'loading',
      'consultaUsuario',
      'usuarioSeleccionado',
      'estadoEditar',
    ]),
    totalPages() {
      return Math.ceil(this.totalUsers / this.itemsPerPage);
    },
    usuariosFiltrados() {
      const userData = JSON.parse(localStorage.getItem('user_data'));
      const dniUser = this.$store.state.consultaUsuario.numero_documento || userData.numero_documento;
      return this.listadoUsuarios.filter((usuario) => usuario.pk_usuario !== dniUser);
    },
    visiblePages() {
      let start = Math.max(1, this.currentPage - 1);
      const end = Math.min(this.totalPages, start + 2);
      start = Math.max(1, end - 2);
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
    showLeftEllipsis() {
      return this.currentPage > 3;
    },
    showRightEllipsis() {
      return this.currentPage < this.totalPages - 2;
    },
  },
  mounted() {
    document.body.setAttribute('data-sidebar', 'colored');
    this.$store.dispatch('updateEstadoEditar', false);
    // this.listarUsuarios();
    this.fetchUsers(1);
  },
  methods: {
    handleCloseError() {
      this.showModalError = false;
    },
    // eslint-disable-next-line camelcase
    confirmarEliminar(pk_usuario) {
      // eslint-disable-next-line camelcase
      this.usuarioAEliminar = pk_usuario;
      const usuarioDetail = this.listadoUsuarios.filter((item) => item.pk_usuario === this.usuarioAEliminar);
      this.usuarioTipo = usuarioDetail;
      if (this.usuarioTipo[0].flag === '1' && this.usuarioTipo[0].flag !== 4) {
        this.modalCustom.open = true;
        this.modalCustom.type = 'success';
        this.modalCustom.title = 'CONFIRMAR';
        this.modalCustom.message = '<p>¿Estas seguro de inhabilitar?.</p>';
      } else {
        this.modalCustom.open = true;
        this.modalCustom.type = 'success';
        this.modalCustom.title = 'CONFIRMAR';
        this.modalCustom.message = '<p>¿Estas seguro de habilitar?.</p>';
      }
      // this.showModalPregunta = true;
    },
    handleClosePregunta() {
      this.showModalPregunta = false;
    },
    async listarUsuarios() {
      try {
        this.$store.state.loading = true;
        const response = await ApiService.getListarUsuarios();
        if (response.status === 200 || response.status === 208) {
          this.$store.state.loading = false;
          this.listadoUsuarios = response.data.results;
          this.totalUsers = response.data.count;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            this.$store.state.loading = false;
            this.showModalError = true;
            if (error.response.data.errors) {
              this.$store.state.loading = false;
              this.showModalError = true;
              this.errores = error.response.data.errors;
            }
          }
        } else if (error.request) {
          this.$store.state.loading = false;
          this.showModalError = true;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      }
    },
    async fetchUsers(page) {
      try {
        this.$store.state.loading = true;
        const params = {
          consolidado: 1,
          l: this.itemsPerPage,
          o: (page - 1) * this.itemsPerPage,
        };
        // const response = await this.getListarUsuarios(params);
        const response = await ApiService.getListarUsuarios(params);
        if (response.status === 200 || response.status === 208) {
          this.$store.state.loading = false;
          this.users = response.data.results;
          this.listadoUsuarios = this.users;
          this.totalUsers = response.data.count;
          this.currentPage = page;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching users:', error);
      }
    },
    goToPage(page) {
      if (page !== this.currentPage && page >= 1 && page <= this.totalPages) {
        this.fetchUsers(page);
      }
    },
    async buscarUsuarios() {
      try {
        const params = {};
        this.$store.state.loading = true;
        if (this.username) {
          params.username = this.username;
        }
        if (this.numero_documento) {
          params.numero_documento = this.numero_documento;
        }
        if (this.q_persona) {
          params.q_persona = this.q_persona;
        }
        const response = await ApiService.getListarUsuarios(params);
        // const response = await ApiService.getListarUsuarios({ params: { username: this.username } });
        if (response.status === 200 || response.status === 208) {
          this.$store.state.loading = false;
          this.listadoUsuarios = response.data.results;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            this.$store.state.loading = false;
            this.showModalError = true;
            if (error.response.data.errors) {
              this.$store.state.loading = false;
              this.showModalError = true;
              this.errores = error.response.data.errors;
            }
          }
        } else if (error.request) {
          this.$store.state.loading = false;
          this.showModalError = true;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      }
    },
    async eliminarUsuario() {
      try {
        const usuarioFilter = this.listadoUsuarios.filter((item) => item.pk_usuario === this.usuarioAEliminar);
        this.usuarioSelected = usuarioFilter;
        const body = {
          pk_usuario: this.usuarioAEliminar,
          flag: this.usuarioSelected[0].flag === '1' ? '0' : '1',
        };
        const response = await ApiService.getEliminarUsuarios(body);
        if (response.status === 200 || response.status === 208) {
          this.$store.state.loading = false;
          // this.showModalPregunta = false;
          this.modalCustom.open = false;
          this.listarUsuarios();
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            this.$store.state.loading = false;
            this.showModalError = true;
            if (error.response.data.errors) {
              this.$store.state.loading = false;
              this.showModalError = true;
              this.errores = error.response.data.errors;
            }
          }
        } else if (error.request) {
          this.$store.state.loading = false;
          this.showModalError = true;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      }
    },
    // eslint-disable-next-line camelcase
    editarUsuario2(usuario) {
      // eslint-disable-next-line camelcase
      this.usuarioAEditar = usuario;
      this.$store.dispatch('updateEstadoEditar', true);
      this.$store.dispatch('updateUsuarioSeleccionado', this.usuarioAEditar);
      this.$router.push('/usuario');
    },
    async limpiarUsuarios() {
      // eslint-disable-next-line no-unused-expressions, no-sequences
      this.username = '',
      this.numero_documento = '',
      this.q_persona = '';
      this.listarUsuarios();
    },
    agregarUsuario() {
      this.$router.push('/usuario');
    },
    handleCloseModal() {
      this.modalCustom.open = false;
    },
  },
};
</script>
