<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable max-len -->
<template>
  <div
    id="content-wrapper"
    class="d-flex flex-column">
    <div
      v-if="loading"
      class="invex-loader">
      <div class="invex-loader_spinner" />
    </div>
    <div id="content">
      <header-component />
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="wrap-login">
              <div class="box-login bg-gray-light radius">
                <div class="circle">
                  <img src="@/assets/escudo.png">
                </div>
                <div class="title mb-4">
                  Sistema de Gestión de Consultas de Planilla Electrónica
                </div>
                <p class="d-none">
                  consultaUsuario {{ consultaUsuario }}
                </p>
                <p class="d-none">
                  grupo {{ grupo }}
                </p>
                <div class="box-form-login box-identidad">
                  <div class="mb-3">
                    <div class="box-texto-correo">
                      <p> {{ correo }}</p>
                      <h4>Compruebe su identidad</h4>
                      <p class="text-danger">
                        Por favor, ingrese el código de verificacicion que se ha
                        enviado a su correo {{ correo }} para iniciar sesión.
                      </p>
                    </div>
                    <div class="input-group">
                      <div class="input-group-text">
                        <i class="mdi mdi-account-circle" />
                      </div>
                      <input
                        v-model="codigoIdentidad"
                        type="text"
                        class="form-control"
                        maxlength="5"
                        placeholder="Ingresa código de verificación"
                        :class="{'is-invalid': submitted && v$.codigoIdentidad.$error }"
                        @input="filterCodVerifica">
                    </div>
                    <div
                      v-if="submitted && v$.codigoIdentidad.$error"
                      class="invalid-feedback">
                      <span v-if="v$.codigoIdentidad.$error">{{ v$.codigoIdentidad.$errors[0].$message }}</span>
                    </div>
                  </div>
                  <div class="form-group">
                    <button
                      type="button"
                      class="btn btn-block btn-warning"
                      @click="validaVerificacion">
                      Ingresar
                    </button>
                  </div>
                </div>
                <div class="text-center">
                  <router-link class="text-muted" :to="{ name: 'recupera-clave' }">
                    <i class="mdi mdi-lock me-1" />
                    ¿Olvidó su contraseña?
                  </router-link>
                </div>
                <div class="box-descargas mt-4">
                  <a
                    href="#"
                    class="btn btn-danger btn-icon-split">
                    <span class="text">Descargar solicitud de acceso</span>
                  </a>
                  <a
                    href="#"
                    class="btn btn-danger btn-icon-split">
                    <span class="text">Descargar manual de acceso</span>
                  </a>
                </div>
                <div class="dashboard" @click="irPageConsulta">
                  Vista Motivos
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-component />
    <modal-error
      v-if="showModalError"
      :open="showModalError"
      :message="errorMessage"
      @close="handleCloseError" />
    <modal-custom
      v-if="modalCustom.open"
      :open="modalCustom.open"
      :type="modalCustom.type"
      :title="modalCustom.title"
      :message="modalCustom.message"
      @close="handleCloseModal" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
// eslint-disable-next-line import/no-unresolved, import/extensions
import { required, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/no-unresolved, import/extensions
import ApiService from '@/apiservices';
// eslint-disable-next-line import/no-unresolved
import HeaderComponent from '@/components/HeaderComponent.vue';
// eslint-disable-next-line import/no-unresolved
import FooterComponent from '@/components/FooterComponent.vue';
// eslint-disable-next-line import/no-unresolved
import ModalError from '@/components/ModalError.vue';
// eslint-disable-next-line import/no-unresolved
import ModalCustom from '@/components/ModalCustom.vue';

export default {
  name: 'LoginIdentidadView',
  components: {
    HeaderComponent,
    FooterComponent,
    ModalError,
    ModalCustom,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    codigoIdentidad: {
      required: helpers.withMessage('Código verificación es requerido', required),
    },
  },
  data() {
    return {
      showModalError: false,
      codigoIdentidad: '',
      submitted: false,
      modalCustom: {
        open: false,
        title: '',
        message: '',
        type: 'success',
        customHtml: '',
      },
      errorMessage: '',
    };
  },
  computed: {
    ...mapState(['dobleFactor', 'loading']),
    ...mapState(['consultaUsuario']),
    tipoEstado() {
      return this.$store.state.consultaUsuario.estado;
    },
    correo() {
      return this.$store.state.consultaUsuario.email;
    },
    grupo() {
      return this.$store.state.consultaUsuario.pk_grupo;
    },
  },
  mounted() {
  },
  methods: {
    async validaVerificacion() {
      this.submitted = true;
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        const body = {
          codigo: this.codigoIdentidad,
        };
        try {
          this.$store.state.loading = true;
          const response = await ApiService.postValidaVerificacion(body);
          if (response.status === 200 || response.status === 208) {
            this.$store.state.loading = false;
            this.respuesta = response.data;
            if (this.grupo !== null) {
              if (this.grupo === 2) {
                this.$router.push('/consulta');
              } else if (this.grupo === 3) {
                this.$router.push('/auditoria');
              } else {
                this.$router.push('/panel');
              }
            } else {
              this.$router.push('/panel');
            }
          } else {
            this.$store.state.loading = false;
            this.showModalError = true;
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 403) {
              this.$store.state.loading = false;
              this.showModalError = true;
              this.errorMessage = error.response.data.detail || 'No tienes permisos para acceder a este recurso.';
            } else if (error.response.status === 400) {
              this.$store.state.loading = false;
              this.showModalError = true;
              if (error.response.data.errors) {
                this.$store.state.loading = false;
                this.showModalError = true;
                this.errorMessage = 'Error en la solicitud. Por favor revisa los datos.';
              }
            }
          } else if (error.request) {
            this.$store.state.loading = false;
            this.showModalError = true;
            this.errorMessage = 'No se ha podido conectar con el servidor.';
          } else {
            this.$store.state.loading = false;
            this.showModalError = true;
            this.errorMessage = 'Ha ocurrido un error desconocido.';
          }
        }
      } else {
        // Usuario inválido
      }
    },
    filterCodVerifica(event) {
      this.codigoIdentidad = event.target.value.replace(/[^0-9]/g, '');
    },
    irPageConsulta() {
      this.$router.push('/consulta');
    },
    handleCloseError() {
      this.showModalError = false;
    },
    handleCloseModal() {
      this.modalCustom.open = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
