<template>
  <div>
    <div
      v-if="loading"
      class="invex-loader">
      <div class="invex-loader_spinner" />
    </div>
    <header-main />
    <sidebar-main />
    <div class="main-content">
      <div
        class="page-content"
        :class="mainContentClass">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0 font-size-18">
                  Consultas 4ta Categoría
                </h4>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-xl-9">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title mb-3">
                    Ingrese motivo consulta
                  </h4>
                  <form @submit.prevent="guardaMotivo">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="mb-3">
                          <select
                            id="selectMotivo"
                            v-model="selectedMotivo"
                            class="form-select"
                            :class="{'is-invalid': submitted && v$.selectedMotivo.$error }">
                            <option value="">
                              --Seleccionar--
                            </option>
                            <option
                              v-for="item in listaMotivos"
                              :key="item.codigo_motivo"
                              :value="item.codigo_motivo">
                              {{ item.descripcion_motivo }}
                            </option>
                          </select>
                          <div
                            v-if="submitted && v$.selectedMotivo.$error"
                            class="invalid-feedback">
                            <span v-if="v$.selectedMotivo.$error">{{ v$.selectedMotivo.$errors[0].$message }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h4 class="card-title mb-3 mt-3">
                      Criterios de busqueda
                    </h4>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="mb-3">
                          <label
                            for="inputExpediente"
                            class="form-label">Ingrese numero de expediente </label>
                          <input
                            id="inputExpediente"
                            v-model="numeroExpediente"
                            type="text"
                            class="form-control"
                            placeholder="Ingrese numero de expediente"
                            :class="{'is-invalid': submitted && v$.numeroExpediente.$error }">
                          <div
                            v-if="submitted && v$.numeroExpediente.$error"
                            class="invalid-feedback">
                            <span v-if="v$.numeroExpediente.$error">{{ v$.numeroExpediente.$errors[0].$message }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="mb-3">
                          <label
                            for="selectTipoDoc"
                            class="form-label">Tipo Documento</label>
                          <select
                            id="selectTipoDocumento"
                            v-model="selectedDocumento"
                            class="form-select"
                            :class="{'is-invalid': submitted && v$.selectedDocumento.$error }">
                            <option value="">
                              --Seleccionar--
                            </option>
                            <option
                              v-for="item in listaDocumentos"
                              :key="item.codigo"
                              :value="item.codigo">
                              {{ item.descripcion }}
                            </option>
                          </select>
                          <div
                            v-if="submitted && v$.selectedDocumento.$error"
                            class="invalid-feedback">
                            <span v-if="v$.selectedDocumento.$error">
                              {{ v$.selectedDocumento.$errors[0].$message }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="mb-3">
                          <label
                            for="inputNomDoc"
                            class="form-label">N° de Documento</label>
                          <div class="input-group">
                            <input
                              id="inputDocumento"
                              v-model="numeroDocumento"
                              type="text"
                              class="form-control"
                              :class="{'is-invalid': submitted && v$.numeroDocumento.$error }"
                              mixlength="8"
                              maxlength="8"
                              placeholder="Ingrese número de documento"
                              @input="filterNumDoc">
                            <button
                              id="btnBusqueda"
                              type="button"
                              class="btn btn-outline-secondary">
                              <i
                                class="mdi mdi-account-search"
                                @click="buscarDocumento" />
                            </button>
                            <button
                              type="button"
                              class="btn btn-outline-secondary">
                              <i
                                class="mdi mdi-eraser"
                                @click="borrarDatos" />
                            </button>
                          </div>
                          <div
                            v-if="submitted && v$.numeroDocumento.$error"
                            class="invalid-feedback d-block">
                            <span v-if="v$.numeroDocumento.$error">{{ v$.numeroDocumento.$errors[0].$message }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="mb-3">
                          <label
                            for="inputNombre"
                            class="form-label">Nombres </label>
                          <input
                            id="inputNombre"
                            v-model="infoUsuario.nombres"
                            type="text"
                            disabled
                            placeholder="Ingrese Nombres"
                            class="form-control">
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="mb-3">
                          <label
                            for="inputPaterno"
                            class="form-label">Apellido Paterno</label>
                          <input
                            id="inputPaterno"
                            v-model="infoUsuario.apellido_paterno"
                            type="text"
                            disabled
                            placeholder="Ingrese Apellido Paterno"
                            class="form-control">
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="mb-3">
                          <label
                            for="inputMAterno"
                            class="form-label">Apellido Materno</label>
                          <input
                            id="inputMAterno"
                            v-model="infoUsuario.apellido_materno"
                            type="text"
                            disabled
                            placeholder="Ingrese Apellido Materno"
                            class="form-control">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="mb-3">
                          <label
                            for="inputNomDoc"
                            class="form-label">Período Inicial</label>
                          <div class="wrap-fecha">
                            <div class="input-group">
                              <input
                                id="seleccionFecha"
                                type="text"
                                autocomplete="off"
                                :value="dateCalendar"
                                class="form-control seleccionFecha"
                                placeholder="Seleccionar Fecha"
                                :class="{'is-invalid': submitted && v$.calendarSelected.$error }"
                                @click="isHidden = !isHidden">
                              <div
                                v-if="submitted && v$.calendarSelected.$error"
                                class="invalid-feedback">
                                <span v-if="v$.calendarSelected.$error">
                                  {{ v$.calendarSelected.$errors[0].$message }}
                                </span>
                              </div>
                              <div class="icon-calendar">
                                <svg
                                  width="18"
                                  height="21"
                                  viewBox="0 0 18 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M13 11.5H10C9.45 11.5 9 11.95 9 12.5V15.5C9 16.05 9.45 16.5 10 16.5H13C13.55 16.5 14 16.05 14 15.5V12.5C14 11.95 13.55 11.5 13 11.5ZM13 1.5V2.5H5V1.5C5 0.95 4.55 0.5 4 0.5C3.45 0.5 3 0.95 3 1.5V2.5H2C0.89 2.5 0.00999999 3.4 0.00999999 4.5L0 18.5C0 19.6 0.89 20.5 2 20.5H16C17.1 20.5 18 19.6 18 18.5V4.5C18 3.4 17.1 2.5 16 2.5H15V1.5C15 0.95 14.55 0.5 14 0.5C13.45 0.5 13 0.95 13 1.5ZM15 18.5H3C2.45 18.5 2 18.05 2 17.5V7.5H16V17.5C16 18.05 15.55 18.5 15 18.5Z"
                                    fill="#424242" />
                                </svg>
                              </div>
                            </div>
                            <div
                              v-show="isHidden"
                              class="wrapp-fecha-rango">
                              <date-picker
                                v-model="fechaInicial"
                                :masks="masks" />
                              <div class="row-accion-cal">
                                <button
                                  class="link-red"
                                  @click="borrarFecha">
                                  Borrar
                                </button>
                                <button
                                  class="link-red"
                                  @click="aceptarFecha">
                                  Aceptar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="mb-3">
                          <label
                            for="inputNomDoc"
                            class="form-label">Período Final</label>
                          <div class="wrap-fecha">
                            <div class="input-group">
                              <input
                                id="seleccionFechaFinal"
                                type="text"
                                autocomplete="off"
                                :value="dateCalendarFinal"
                                class="form-control seleccionFecha"
                                placeholder="Seleccionar Fecha"
                                :class="{'is-invalid': submitted && v$.calendarSelectedFinal.$error }"
                                @click="isHiddenFinal = !isHiddenFinal">
                              <div
                                v-if="submitted && v$.calendarSelectedFinal.$error"
                                class="invalid-feedback">
                                <span v-if="v$.calendarSelectedFinal.$error">
                                  {{ v$.calendarSelectedFinal.$errors[0].$message }}
                                </span>
                              </div>
                              <div class="icon-calendar">
                                <svg
                                  width="18"
                                  height="21"
                                  viewBox="0 0 18 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M13 11.5H10C9.45 11.5 9 11.95 9 12.5V15.5C9 16.05 9.45 16.5 10 16.5H13C13.55 16.5 14 16.05 14 15.5V12.5C14 11.95 13.55 11.5 13 11.5ZM13 1.5V2.5H5V1.5C5 0.95 4.55 0.5 4 0.5C3.45 0.5 3 0.95 3 1.5V2.5H2C0.89 2.5 0.00999999 3.4 0.00999999 4.5L0 18.5C0 19.6 0.89 20.5 2 20.5H16C17.1 20.5 18 19.6 18 18.5V4.5C18 3.4 17.1 2.5 16 2.5H15V1.5C15 0.95 14.55 0.5 14 0.5C13.45 0.5 13 0.95 13 1.5ZM15 18.5H3C2.45 18.5 2 18.05 2 17.5V7.5H16V17.5C16 18.05 15.55 18.5 15 18.5Z"
                                    fill="#424242" />
                                </svg>
                              </div>
                            </div>
                            <div
                              v-show="isHiddenFinal"
                              class="wrapp-fecha-rango">
                              <date-picker
                                v-model="fechaFinal"
                                :masks="masks" />
                              <div class="row-accion-cal">
                                <button
                                  class="link-red"
                                  @click="borrarFechaFinal">
                                  Borrar
                                </button>
                                <button
                                  class="link-red"
                                  @click="aceptarFechaFinal">
                                  Aceptar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="mb-3 mt-4 hstack gap-3">
                          <button
                            type="submit"
                            class="btn btn-primary w-md"
                            @click="buscarPlanilla">
                            Buscar
                          </button>
                          <button
                            class="btn btn-warning w-md"
                            @click="limpiarDatos">
                            Limpiar
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-xl-9">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">
                    Lista Registro
                  </h4>
                  <p class="d-none">
                    tokenPayload {{ tokenPayload }}
                  </p>
                  <div class="row-fechas">
                    <p class="card-title-desc">
                      Fecha última actualización: 03/08/2024
                    </p>
                    <p class="card-title-desc">
                      Fecha y hora de consulta: {{ currentDateTime }}
                    </p>
                  </div>
                  <div class="box-pdf-row">
                    <a
                      href="#"
                      @click.prevent="downloadPDF">
                      <i class="mdi mdi-file-pdf" />
                    </a>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered mb-0">
                      <tbody>
                        <tr>
                          <th
                            v-if="infoUsuario"
                            class="bg-warning">
                            Trabajador:
                            <span>
                              {{ infoUsuario.nombres }} {{ infoUsuario.apellido_paterno }} {{ infoUsuario.materno }}
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <td colspan="7">
                            <table class="table mb-0">
                              <thead class="table-light">
                                <tr>
                                  <th>Periodo</th>
                                  <th>Empresa</th>
                                  <th>Tipo Contribuyente</th>
                                  <th>Número de recibo</th>
                                  <th>Monto de pago</th>
                                  <th>Fecha Emisión</th>
                                  <th>Fecha de pago</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(usuario) in listaFormateada"
                                  :key="usuario.pk_usuario">
                                  <td>{{ usuario.periodo }}</td>
                                  <td>{{ usuario.razon_social }}</td>
                                  <td>{{ usuario.tipo_contrato }}</td>
                                  <td>{{ usuario.numero_recibo }}</td>
                                  <td>{{ usuario.pago_monto }}</td>
                                  <td>{{ usuario.fecha_emision }}</td>
                                  <td>{{ usuario.fecha_pago }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer-main />
      <modal-error
        v-if="showModalError"
        :open="showModalError"
        :message="errorMessage"
        @close="handleCloseError" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { required, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
// eslint-disable-next-line import/extensions, import/no-unresolved
import jwtDecode from 'jwt-decode';
// eslint-disable-next-line import/extensions, import/no-unresolved
import ApiService from '@/apiservices';
// eslint-disable-next-line import/no-unresolved
import ModalError from '@/components/ModalError.vue';
// eslint-disable-next-line import/no-unresolved
import HeaderMain from '@/components/HeaderMain.vue';
// eslint-disable-next-line import/no-unresolved
import SidebarMain from '@/components/SidebarMain.vue';
// eslint-disable-next-line import/no-unresolved
import FooterMain from '@/components/FooterMain.vue';

// eslint-disable-next-line import/no-unresolved
import logoMinstra from '@/assets/images/logo.png';
// eslint-disable-next-line import/extensions, import/no-unresolved
import { myData } from '@/data.js';

export default {
  name: 'ConsultaView',
  components: {
    DatePicker,
    HeaderMain,
    SidebarMain,
    FooterMain,
    ModalError,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    selectedMotivo: {
      required: helpers.withMessage('Motivo de consulta es requerido', required),
    },
    numeroExpediente: {
      required: helpers.withMessage('Número de expediente es requerido', required),
    },
    selectedDocumento: {
      required: helpers.withMessage('Tipo documento es requerido', required),
    },
    numeroDocumento: {
      required: helpers.withMessage('Número documento es requerido', required),
    },
    calendarSelected: {
      required: helpers.withMessage('Periodo inicial es requerido', required),
    },
    calendarSelectedFinal: {
      required: helpers.withMessage('Periodo Final es requerido', required),
    },
  },
  data() {
    const date = new Date();
    const month = date.getMonth();
    const monthPrev = (date.getMonth() - 1);
    const year = date.getFullYear();
    const day = date.getDate();
    return {
      token: '',
      payload: null,
      loadedData: [],
      info: null,
      listaMotivos: [],
      selectedMotivo: '',
      listaDocumentos: [],
      selectedDocumento: '',
      listaConsulta: [],
      numeroDocumento: '',
      categoria: 'cuarta',
      infoUsuario: '',
      numeroExpediente: '',
      isHidden: false,
      isHiddenFinal: false,
      calendarSelected: null,
      calendarSelectedFinal: null,
      masks: {
        input: 'DD-MM-YYYY',
      },
      range: {
        start: new Date(year, monthPrev, day),
        end: new Date(year, month, day),
      },
      fechaInicial: new Date(year, monthPrev, day),
      fechaFinal: new Date(year, month, day),
      showModalError: false,
      year: new Date().getFullYear(),
      // eslint-disable-next-line array-bracket-newline
      headers: [
        'Periodo',
        'Empresa',
        'Tipo',
        'Recibo',
        'Monto',
        'Fecha',
        'Pago',
      ],
      currentDateTime: '',
      base64String: '',
      // eslint-disable-next-line max-len
      /* base64String: 'iVBORw0KGgoAAAANSUhEUgAAAZoAAAGaAQAAAAAefbjOAAAC30lEQVR4nO2bQY6jMBBFfw1IWZob9FHMzVq5mTlKboCXkYj+LKpsnOne9PQMpKFYEeApRi5V/frYQnz5mH59nQEccsghhxxyyKFjQmJHD0yD3ZExi4gMDwFyeWDcZXgObQ9FkuQMyIiHTj6moSPi7UIAHUmSz9B2w3NoeyhbAmDKPXT6OQO8vi36gGaQvYbn0G6QjGEBJhERebuLvM+AjP/jnxx6Taj/4zeRL5RIAMg9OAlA5L2G59BuUCCZYPmACQ+RER0R5456jVz2G55DW0NaJga7KGO+0M70SlGbOw3Poa2rxmplE6ipINyF0wBh+8C2w3NocwhNVxlnAEBXp78jOXcEwgKmsFgTkl78nRz6F1Dgh4kn79pwMOUeQO4h407Dc2hjiAkP4VV6yIiHmF0pF1WWFi/oVHz+kHdy6DtVoy0OsL6CnC0Eyk941TgB1OgInfOkpaNqC5JMYYE2qB4Rh4caxxqRVhxgHzIsMyCWbGEB8uLv5NB3IMsRa5sRZwsGi4OSGcoNj4iDQ6VqlF5DE0WcAUQuUG1hiqKj54jjQ7XTbIWDxkYjHAItGDxHHB5qqoYKhwTLByohAKh15TniJFCTIywEtHQsQCsr1qDxiDg4VCYZbddRIqSUDr07w3uNE0ClaqyfurF++e6qenA/4nRQ7tWXYgqkyNCR5F14FfOzmbKvvD0V1FHebyIWG1lEF0ms9kStHz/nnRz6m6NUjdpk1k9b1mGUIhJn7zXOADU6woyo0FqYjVHhOuIc0FP3yScjal1YGcsNzxFngZo9XbZz4yEiQ1fcy9zrUkzXEaeBYrUiEmwbD+KtB/RTaCg6YqfhObSLi12049L4EbaUBoDriLNCk1xoBkSwdZYmIW6+y+8M0IccsdoOtd3U53wt9jmg1o+wClFWXD4vzEdtRj0iDg21+zUsGJ79iJo3dJGdR8TRoU/2dAHtJq6w1HPvNRxyyCGHHHLIofb4DbKt5fHWPSYxAAAAAElFTkSuQmCC', */
      errorMessage: '',
      submitted: false,
    };
  },
  computed: {
    ...mapState([
      'loading',
      'consultaUsuario',
      'usuarioSeleccionado',
      'tokenPayload',
    ]),
    tipoUsuario() {
      return this.$store.state.consultaUsuario?.compartir_datos || '';
    },
    mainContentClass() {
      return this.$route.name || '';
    },
    listaFormateada() {
      return this.listaConsulta.map((item) => ({
        ...item,
        fecha_emision: this.formatearFecha(item.fecha_emision),
        fecha_pago: this.formatearFecha(item.fecha_pago),
        fecha_laboral: item.fecha_laboral,
      }));
    },
    items() {
      return this.listaFormateada.map((item) => ({
        Periodo: item.periodo,
        Empresa: item.razon_social,
        Tipo: item.tipo_contrato,
        Recibo: item.numero_recibo,
        Monto: item.pago_monto,
        Fecha: item.fecha_emision,
        Pago: item.fecha_pago,
      }));
    },
    anioAnterior() {
      const d = new Date();
      d.setFullYear(d.getFullYear() - 1);
      return d.toDateString();
    },
    dateCalendar() {
      const optionFecha = {
        weekday: 'short', year: 'numeric', month: 'short', day: 'numeric',
      };
      const textoDefault = 'Seleccione Fecha';
      if (!this.calendarSelected) return textoDefault;
      const valorfechaStart = this.fechaInicial.toLocaleDateString('es-ES', optionFecha);
      const fechaFormat = valorfechaStart.split(' ').slice(1).join(' ');
      return `${fechaFormat}`;
    },
    dateCalendarFinal() {
      const optionFecha = {
        weekday: 'short', year: 'numeric', month: 'short', day: 'numeric',
      };
      const textoDefault = 'Seleccione Fecha';
      if (!this.calendarSelectedFinal) return textoDefault;
      const valorfechaFinal = this.fechaFinal.toLocaleDateString('es-ES', optionFecha);
      const fechaFormat = valorfechaFinal.split(' ').slice(1).join(' ');
      return `${fechaFormat}`;
    },
  },
  mounted() {
    this.getCurrentDateTime();
    this.listadoMotivos();
    this.listaTipoDocumento();
  },
  methods: {
    async guardarConsulta() {
      const userData = JSON.parse(localStorage.getItem('user_data'));
      const motivoItem = this.listaMotivos.filter((item) => item.codigo_motivo === this.selectedMotivo);
      this.motivoSelected = motivoItem;
      const body = {
        numero_documento: this.numeroDocumento,
        tipo_documento: this.selectedDocumento,
        nombre_solicitante: this.infoUsuario.nombres || '',
        apellido_paterno_solicitante: this.infoUsuario.apellido_paterno || '',
        apellido_materno_solicitante: this.infoUsuario.apellido_materno || '',
        pk_motivo: this.motivoSelected.pk_motivo,
        descripcion_motivo: this.motivoSelected.descripcion_motivo,
        numero_expediente: this.numeroExpediente,
        usuario_id: userData ? userData.numero_documento : '',
      };
      try {
        this.$store.state.loading = true;
        const response = await ApiService.getGuardarConsulta(body);
        if (response.status === 200 || response.status === 208) {
          this.base64String = response.data.qr_code_base64;
          this.$store.state.loading = false;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            this.$store.state.loading = false;
            this.showModalError = true;
            this.errorMessage = error.response.data.error || 'No se ha podido conectar con el servidor.';
            if (error.response.data.errors) {
              this.$store.state.loading = false;
              this.showModalError = true;
              this.errores = error.response.data.errors;
            }
          } else {
            this.$store.state.loading = false;
            this.showModalError = true;
          }
        } else if (error.request) {
          this.$store.state.loading = false;
          this.showModalError = true;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      }
    },
    formatearFecha(fecha) {
      if (fecha === '-') return '-';
      const date = new Date(fecha);
      const dia = String(date.getDate()).padStart(2, '0');
      const mes = String(date.getMonth() + 1).padStart(2, '0');
      const año = String(date.getFullYear()).slice(-2);
      return `${dia}/${mes}/${año}`;
    },
    formatearPeriodo(periodo) {
      const año = periodo.substring(0, 4);
      const mes = periodo.substring(4);
      return `${mes}/${año.slice(-2)}`;
    },
    formatearMonto(monto) {
      return new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' }).format(monto);
    },
    getTokenFromUrl() {
      // Obtener el valor del token desde la URL
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      this.token = urlParams.get('token');
      if (this.token) {
        this.decodeToken(this.token);
      } else if (!this.tokenPayload) {
        this.$router.push('/');
      }
    },
    decodeToken(token) {
      try {
        // Decodificar el token usando jwt-decode
        this.payload = jwtDecode(token);
        this.$store.dispatch('saveTokenPayload', this.payload);
        // Validar si tipo_autenticacion es "1"
        /* if (this.tokenPayload.tipo_autenticacion === '1') { */
        if (this.tokenPayload.tipo_autenticacion === '1') {
          this.$router.push('/consulta');
        } else {
          this.$router.push('/');
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error decoding token:', error);
      }
    },
    loadData() {
      this.$store.state.loading = true;
      // Simulamos una carga asíncrona
      setTimeout(() => {
        this.listaConsulta = myData;
        this.$store.state.loading = false;
      }, 1000);
    },
    getCurrentDateTime() {
      const currentDate = new Date();
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      };
      this.currentDateTime = currentDate.toLocaleString('es-ES', options);
    },
    downloadPDF() {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF();
      // Generar QR
      const base64QRCode = this.base64String;
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const qrWidth = 30;
      const qrHeight = 30;
      const qrX = pageWidth - qrWidth - 10; // 10 mm de margen derecho
      const qrY = (pageHeight - 35);
      doc.addImage(base64QRCode, 'PNG', qrX, qrY, qrWidth, qrHeight);
      // Generar Logo Mintra
      const imgData = logoMinstra;
      const imgWidth = 328 * 0.264583;
      const imgHeight = 54 * 0.264583;
      doc.setFontSize(16);
      doc.text('SISTEMA: PLANILLA ELECTRÓNICA', 10, 10);
      doc.setFontSize(12);
      doc.text('Módulo : Consulta de Remuneración de Trabajadores', 10, 18);
      doc.addImage(imgData, 'JPEG', 10, 25, imgWidth, imgHeight);

      // Obtener Fecha
      const dateString = this.currentDateTime;
      const pageHeightDate = doc.internal.pageSize.height;
      doc.setFontSize(10);

      // Texto "Fecha y hora de consulta"
      doc.text('Fecha y hora de consulta:', 10, pageHeight - 16);
      doc.text(dateString, 10, pageHeightDate - 10);

      doc.autoTable({
        theme: 'grid',
        headStyles: {
          fillColor: [
            169,
            197,
            237,
          ], // Color de fondo azul para el encabezado
          textColor: [
            0,
            0,
            0,
          ], // Color del texto en negro
          halign: 'center', // Alineación horizontal centrada
        },
        startY: imgHeight + 30,
        head: [this.headers],
        body: this.items.map((item) => this.headers.map((header) => item[header])),
      });
      doc.save('PlanillaElectronica_4ta.pdf');
    },
    handleCloseError() {
      this.showModalError = false;
    },
    async listadoMotivos() {
      try {
        this.$store.state.loading = true;
        const response = await ApiService.getListarMotivos();
        this.listaMotivos = response.data;
        if (response.status === 200 || response.status === 208) {
          this.$store.state.loading = false;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            this.$store.state.loading = false;
            this.showModalError = true;
            if (error.response.data.errors) {
              this.$store.state.loading = false;
              this.showModalError = true;
              this.errores = error.response.data.errors;
            }
          }
        } else if (error.request) {
          this.$store.state.loading = false;
          this.showModalError = true;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      }
    },
    async listaTipoDocumento() {
      try {
        this.$store.state.loading = true;
        const response = await ApiService.getListarTipoDocumento();
        // eslint-disable-next-line
        // const filterDocumento = response.data.filter((item) => item.codigo == '03');
        // this.listaDocumentos = filterDocumento;
        this.listaDocumentos = response.data;
        if (response.status === 200 || response.status === 208) {
          this.$store.dispatch('updateOperacionSeleccionada', 'Hola');
          this.$store.state.loading = false;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            this.$store.state.loading = false;
            this.showModalError = true;
            if (error.response.data.errors) {
              this.$store.state.loading = false;
              this.showModalError = true;
              this.errores = error.response.data.errors;
            }
          }
        } else if (error.request) {
          this.$store.state.loading = false;
          this.showModalError = true;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      }
    },
    async buscarPlanilla() {
      this.submitted = true;
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        const fromDate = this.dateToFormatApi(this.fechaInicial);
        const toDate = this.dateToFormatApi(this.fechaFinal);
        const body = {
          numero_expediente: this.numeroExpediente,
          tipo_documento: this.selectedDocumento,
          numero_documento: this.numeroDocumento,
          fecha_inicio: fromDate,
          fecha_fin: toDate,
          pk_motivo: 1,
          tipo_categoria: this.categoria,
          consolidado: '1',
          numero_ruc: '',
        };
        try {
          this.$store.state.loading = true;
          const response = await ApiService.getPlanilla(body);
          if (response.status === 200 || response.status === 208) {
            this.listaConsulta = response.data;
            this.$store.state.loading = false;
          } else {
            this.$store.state.loading = false;
            this.showModalError = true;
          }
        } catch (error) {
          if (error.response) {
            this.loadData();
            if (error.response.status === 400) {
              this.$store.state.loading = false;
              this.showModalError = true;
              if (error.response.data.errors) {
                this.$store.state.loading = false;
                this.showModalError = true;
                this.errores = error.response.data.errors;
              }
            } else {
              this.$store.state.loading = false;
              this.showModalError = true;
            }
          } else if (error.request) {
            this.$store.state.loading = false;
            this.showModalError = true;
          } else {
            this.$store.state.loading = false;
            this.showModalError = true;
          }
        } finally {
          this.$store.state.loading = false;
          this.guardarConsulta();
        }
        this.getCurrentDateTime();
      }
    },
    async buscarDocumento() {
      try {
        this.$store.state.loading = true;
        const body = {
          tipo_documento: this.selectedDocumento,
          numero_documento: this.numeroDocumento,
          datos: '1',
          easy_data: '1',
        };
        const response = await ApiService.getBuscarDocumento(body);
        if (response.status === 200 || response.status === 208) {
          this.infoUsuario = response.data.datos;
          this.$store.state.loading = false;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            this.$store.state.loading = false;
            this.showModalError = true;
            if (error.response.data.errors) {
              this.$store.state.loading = false;
              this.showModalError = true;
              this.errores = error.response.data.errors;
            }
          }
        } else if (error.request) {
          this.$store.state.loading = false;
          this.showModalError = true;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      }
    },
    borrarDatos() {
      this.selectedDocumento = '';
      this.numeroDocumento = '';
    },
    filterNumDoc(event) {
      this.numeroDocumento = event.target.value.replace(/[^0-9]/g, '');
    },
    guardaMotivo() {
      // alert('evento motivo');
    },
    dateToFormatApi(date) {
      const formatDate = new Date(date);
      const isoDate = formatDate.toISOString().split('T')[0];
      const [year, month] = isoDate.split('-');
      return `${year}${month}`;
    },
    limpiarDatos() {
      this.selectedMotivo = '';
    },
    aceptarFecha() {
      this.isHidden = false;
      this.calendarSelected = this.fechaInicial;
    },
    borrarFecha() {
      this.isHidden = false;
      this.calendarSelected = '';
    },
    aceptarFechaFinal() {
      this.isHiddenFinal = false;
      this.calendarSelectedFinal = this.fechaFinal;
    },
    borrarFechaFinal() {
      this.isHiddenFinal = false;
      this.calendarSelectedFinal = '';
    },
  },
};
</script>
