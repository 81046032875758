import axios from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});
const ApiService = {
  postIniciarSesion(data) {
    axios.defaults.headers.common.Authorization = process.env.VUE_APP_TOKEN_PUBLICO;
    return axiosClient({
      method: 'post',
      url: '/seguridad/api/acceso/',
      data,
      headers: {
        Authorization: process.env.VUE_APP_TOKEN_PUBLICO,
      },
    });
  },
  postVerificacion(data) {
    const tokenCache2 = localStorage.getItem('tokenCache');
    axios.defaults.headers.common.Authorization = tokenCache2;
    return axiosClient({
      method: 'post',
      url: '/seguridad/api/enviar-verificacion/',
      data,
      headers: {
        Authorization: tokenCache2,
      },
    });
  },
  postCredenciales(data) {
    const tokenCacheNuevo = localStorage.getItem('tokenCacheNuevo');
    axios.defaults.headers.common.Authorization = tokenCacheNuevo;
    return axiosClient({
      method: 'post',
      url: '/seguridad/api/enviar-credenciales/',
      data,
      headers: {
        Authorization: tokenCacheNuevo,
      },
    });
  },
  postValidaVerificacion(data) {
    const tokenCache2 = localStorage.getItem('tokenCache');
    axios.defaults.headers.common.Authorization = tokenCache2;
    return axiosClient({
      method: 'post',
      url: '/seguridad/api/validar-verificacion/',
      data,
      headers: {
        Authorization: tokenCache2,
      },
    });
  },
  postActualizarClave(data) {
    const tokenCache2 = localStorage.getItem('tokenCache');
    axios.defaults.headers.common.Authorization = tokenCache2;
    return axiosClient({
      method: 'post',
      url: '/seguridad/api/acceso/',
      data,
      headers: {
        Authorization: tokenCache2,
      },
    });
  },
  getListarUsuarios(params = {}) {
    const tokenCache2 = localStorage.getItem('tokenCache');
    axios.defaults.headers.common.Authorization = tokenCache2;
    return axiosClient({
      method: 'get',
      url: '/seguridad/api/usuario/',
      params,
      headers: {
        Authorization: tokenCache2,
      },
    });
  },
  getEliminarUsuarios(data) {
    const tokenCache2 = localStorage.getItem('tokenCache');
    axios.defaults.headers.common.Authorization = tokenCache2;
    return axiosClient({
      method: 'put',
      url: `/seguridad/api/usuario/${data.pk_usuario}/`,
      data,
      headers: {
        Authorization: tokenCache2,
      },
    });
  },
  getEditarUsuarios(data) {
    const tokenCache2 = localStorage.getItem('tokenCache');
    axios.defaults.headers.common.Authorization = tokenCache2;
    return axiosClient({
      method: 'put',
      url: `/seguridad/api/usuario/${data.pk_usuario}/`,
      data,
      headers: {
        Authorization: tokenCache2,
      },
    });
  },
  getListarTipoDocumento(data) {
    const tokenCache2 = localStorage.getItem('tokenCache');
    axios.defaults.headers.common.Authorization = tokenCache2;
    return axiosClient({
      method: 'get',
      url: '/modelsext/api/tipo_documento/',
      data,
      headers: {
        Authorization: tokenCache2,
      },
    });
  },
  getListarMotivos(data) {
    const tokenCache2 = localStorage.getItem('tokenCache');
    axios.defaults.headers.common.Authorization = tokenCache2;
    return axiosClient({
      method: 'get',
      url: '/programacion/api/motivo/',
      data,
      headers: {
        Authorization: tokenCache2,
      },
    });
  },
  getBuscarDocumento(data) {
    const tokenCache2 = localStorage.getItem('tokenCache');
    axios.defaults.headers.common.Authorization = tokenCache2;
    return axiosClient({
      method: 'post',
      url: '/seguridad/api/validacion/',
      data,
      headers: {
        Authorization: tokenCache2,
      },
    });
  },
  getListarRegion(data) {
    const tokenCache2 = localStorage.getItem('tokenCache');
    axios.defaults.headers.common.Authorization = tokenCache2;
    return axiosClient({
      method: 'get',
      url: '/modelsext/api/regional/',
      data,
      headers: {
        Authorization: tokenCache2,
      },
    });
  },
  getListarZona(codigoRegion) {
    const tokenCache2 = localStorage.getItem('tokenCache');
    axios.defaults.headers.common.Authorization = tokenCache2;
    return axiosClient({
      method: 'get',
      url: '/modelsext/api/zonal/',
      params: {
        codigo_region: codigoRegion,
      },
      headers: {
        Authorization: tokenCache2,
      },
    });
  },
  getListarPerfil(data) {
    const tokenCache2 = localStorage.getItem('tokenCache');
    axios.defaults.headers.common.Authorization = tokenCache2;
    return axiosClient({
      method: 'get',
      url: '/seguridad/api/grupo/',
      data,
      headers: {
        Authorization: tokenCache2,
      },
    });
  },
  getListarInstitucion(data) {
    const tokenCache2 = localStorage.getItem('tokenCache');
    axios.defaults.headers.common.Authorization = tokenCache2;
    return axiosClient({
      method: 'get',
      url: '/modelsext/api/institucion/',
      data,
      headers: {
        Authorization: tokenCache2,
      },
    });
  },
  postCrearUsuario(data) {
    const tokenCache2 = localStorage.getItem('tokenCache');
    axios.defaults.headers.common.Authorization = tokenCache2;
    return axiosClient({
      method: 'post',
      url: '/seguridad/api/acceso/',
      data,
      headers: {
        Authorization: tokenCache2,
      },
    });
  },
  getPlanilla(data) {
    const tokenCache2 = localStorage.getItem('tokenCache');
    axios.defaults.headers.common.Authorization = tokenCache2;
    return axiosClient({
      method: 'post',
      url: '/programacion/api/consultasPlanillas/',
      data,
      headers: {
        Authorization: tokenCache2,
      },
    });
  },
  getConsultaRuc(data) {
    const tokenCache2 = localStorage.getItem('tokenCache');
    axios.defaults.headers.common.Authorization = tokenCache2;
    return axiosClient({
      method: 'post',
      url: '/programacion/api/consultasPlanillas/',
      data,
      headers: {
        Authorization: tokenCache2,
      },
    });
  },
  getListarCargo(data) {
    const tokenCache2 = localStorage.getItem('tokenCache');
    axios.defaults.headers.common.Authorization = tokenCache2;
    return axiosClient({
      method: 'get',
      url: '/modelsext/api/cargo/',
      data,
      headers: {
        Authorization: tokenCache2,
      },
    });
  },
  getConsultaAuditoria(data) {
    const tokenCache2 = localStorage.getItem('tokenCache');
    axios.defaults.headers.common.Authorization = tokenCache2;
    return axiosClient({
      method: 'get',
      url: '/programacion/api/auditoria/',
      data,
      headers: {
        Authorization: tokenCache2,
      },
    });
  },
  getGuardarConsulta(data) {
    const tokenCache2 = localStorage.getItem('tokenCache');
    axios.defaults.headers.common.Authorization = tokenCache2;
    return axiosClient({
      method: 'post',
      url: '/programacion/api/guardar-consulta/',
      data,
      headers: {
        Authorization: tokenCache2,
      },
    });
  },
};

export default ApiService;
