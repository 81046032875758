<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6" />
        <div class="col-sm-6">
          <div class="text-sm-end d-none d-sm-block">
            Mintra 2024.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'FooterMain',
  computed: {
  },
};
</script>

  <style scoped>
  </style>
