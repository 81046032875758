<!-- src/components/HeaderComponent.vue -->
<template>
  <nav class="navbar navbar-expand navbar-light bg-brand topbar static-top shadow">
    <a class="sidebar-brand d-flex align-items-center justify-content-center">
      <div class="logo-brand">
        <img src="@/assets/logo.png">
      </div>
    </a>
    <div class="d-none">
      <p>loading {{ loading }}</p>
      <p>consultaUsuario {{ consultaUsuario }}</p>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'HeaderComponent',
  computed: {
    ...mapState([
      'dobleFactor',
      'loading',
      'consultaUsuario',
    ]),
  },
};
</script>

  <style scoped>
  /* Estilos para el header */
  .app-header {
    background-color: #333;
    color: #fff;
    padding: 1rem;
  }
  .app-header nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  .app-header nav ul li {
    margin-right: 1rem;
  }
  .app-header nav ul li a {
    color: #fff;
    text-decoration: none;
  }
  </style>
