// eslint-disable-next-line import/prefer-default-export
export const myEmpresas = [{
  tipo_categoria: 'Quinta Categoría',
  razon_social: 'DELOSI S.A.',
  ruc: '20100123330',
}, {
  tipo_categoria: 'Cuarta',
  razon_social: 'MINISTERIO DE JUSTICIA',
  ruc: '20120123313',
}];
