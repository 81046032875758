export default {
  setLoading(state, payload) {
    state.loading = payload;
  },

  updatePosts(state, payload) {
    state.posts = payload;
  },

  updateDobleFactor(state, payload) {
    state.dobleFactor = payload;
  },

  updateUsuario(state, payload) {
    state.consultaUsuario = payload;
  },
  setOperacionSeleccionada(state, payload) {
    state.operacionSeleccionada = payload;
  },
  setUsuarioSeleccionado(state, payload) {
    state.usuarioSeleccionado = payload;
  },
  setEstadoEditar(state, payload) {
    state.estadoEditar = payload;
  },
  setTokenPayload(state, payload) {
    state.tokenPayload = payload;
  },
};
