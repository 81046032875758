<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable max-len -->
<template>
  <div
    id="content-wrapper"
    class="d-flex flex-column">
    <div
      v-if="loading"
      class="invex-loader">
      <div class="invex-loader_spinner" />
    </div>
    <div id="content">
      <header-component />
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="wrap-login">
              <div class="box-login-recupera bg-gray-light radius">
                <div class="title mb-4">
                  <h1 class="text-danger">
                    Recuperación de Contraseña
                  </h1>
                </div>
                <div
                  v-if="idPaso === 1" class="box-form-recupera paso-1">
                  <div class="form-group">
                    <div class="mb-3">
                      <label for="numDocumento" class="form-label">Número de Documento</label>
                      <div class="input-group">
                        <div class="input-group-text">
                          <i class="mdi mdi-face-recognition" />
                        </div>
                        <input id="numDocumento" v-model="numDocumento" type="text" class="form-control" placeholder="Ingresar número de documento">
                      </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between gap-2">
                      <button type="submit" class="btn btn-primary w-md" @click="recuperarClave">
                        Continuar
                      </button>
                      <router-link class="btn btn-warning w-md" to="/">
                        Cancelar
                      </router-link>
                    </div>
                  </div>
                </div>
                <div
                  v-if="idPaso === 2" class="box-form-recupera paso-2">
                  <div class="form-group">
                    <div class="mb-3">
                      <label for="correo" class="form-label">La verificación es necesaria. Verifique el correo para enviar</label>
                      <div class="input-group">
                        <input id="correo" v-model="correo" type="text" class="form-control" placeholder="ejemplo@mail.com" disabled readonly>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between gap-2">
                      <button type="submit" class="btn btn-primary w-md" @click="enviarVerificacion">
                        Enviar código de verificación
                      </button>
                      <router-link class="btn btn-warning w-md" to="/">
                        Cancelar
                      </router-link>
                    </div>
                  </div>
                </div>
                <div
                  v-if="idPaso === 3" class="box-form-recupera paso-3">
                  <div class="form-group">
                    <div class="mb-3">
                      <label for="correo" class="form-label">El código de verificación ha sido enviado a tu buzón de correo.
                        Por favor copiar ello al casillero de texto abajo.</label>
                      <div class="input-group">
                        <div class="input-group-text">
                          <i class="mdi mdi-face-recognition" />
                        </div>
                        <input id="correo" v-model="correo" type="text" class="form-control" placeholder="ejemplo@mail.com" disabled readonly>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="codigo" class="form-label">Código de verificación</label>
                      <div class="input-group">
                        <div class="input-group-text">
                          <i class="mdi mdi-account-circle" />
                        </div>
                        <input
                          v-model="codigoIdentidad"
                          type="text"
                          class="form-control"
                          placeholder="Ingresa código de verificación">
                      </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between gap-2">
                      <button type="submit" class="btn btn-primary w-md" @click="validaVerificacion">
                        Verificar código
                      </button>
                      <router-link class="btn btn-warning w-md" to="/">
                        Cancelar
                      </router-link>
                    </div>
                  </div>
                </div>
                <div
                  v-if="idPaso === 4" class="box-form-recupera paso-4">
                  <div class="mb-3">
                    <div class="box-texto-correo">
                      <h4>Actualizar Contraseña</h4>
                      <p>Ingrese su nueva contraseña</p>
                      <p class="text-danger">
                        La nueva contraseña debe ser de 12 dígitos, debe tener al menos una letra minúscula,
                        al menos una letra mayúscula, al menos un número, al menos un carácter especial
                      </p>
                    </div>
                    <div class="group-input">
                      <div class="mb-3">
                        <label class="form-label">Ingrese Contraseña</label>
                        <input
                          v-model="password"
                          type="password"
                          class="form-control"
                          placeholder="Ingrese Contraseña"
                          :class="{'is-invalid': submitted && v$.password.$error }">
                        <div
                          v-if="submitted && v$.password.$error"
                          class="invalid-feedback">
                          <span v-if="v$.password.$error">{{ v$.password.$errors[0].$message }}</span>
                        </div>
                      </div>
                      <div>
                        <label class="form-label">Repetir Contraseña</label>
                        <input
                          v-model="confirmPassword"
                          type="password"
                          class="form-control"
                          placeholder="Ingrese Contraseña"
                          :class="{'is-invalid': submitted && v$.confirmPassword.$error }">
                        <div
                          v-if="submitted && v$.confirmPassword.$error"
                          class="invalid-feedback">
                          <span v-if="v$.confirmPassword.$error">{{ v$.confirmPassword.$errors[0].$message }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-wrap justify-content-start gap-2">
                    <button
                      type="button"
                      class="btn btn-primary w-md"
                      @click="actualizarContrasena">
                      Actualizar
                    </button>
                    <router-link class="btn btn-warning w-md" to="/">
                      Cancelar
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-component />
    <modal-error
      v-if="showModalError"
      :open="showModalError"
      @close="handleCloseError" />
    <modal-exitoso
      v-if="showModal"
      :open="showModal"
      @close="handleClose" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
// eslint-disable-next-line import/no-unresolved, import/extensions
import { required, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/extensions, import/no-unresolved
import ApiService from '@/apiservices';
// eslint-disable-next-line import/no-unresolved
import HeaderComponent from '@/components/HeaderComponent.vue';
// eslint-disable-next-line import/no-unresolved
import FooterComponent from '@/components/FooterComponent.vue';
// eslint-disable-next-line import/no-unresolved
import ModalError from '@/components/ModalError.vue';
// eslint-disable-next-line import/no-unresolved
import ModalExitoso from '@/components/ModalExitoso.vue';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{12,}$/;

const strongPassword = helpers.withMessage(
  'La contraseña debe tener al menos 12 caracteres, incluir al menos una minúscula, una mayúscula, un número y un carácter especial',
  (value) => passwordRegex.test(value),
);

export default {
  name: 'RecuperarClaveView',
  components: {
    HeaderComponent,
    FooterComponent,
    ModalError,
    ModalExitoso,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      password: {
        required: helpers.withMessage('La contraseña es requerida', required),
        strongPassword,
      },
      confirmPassword: {
        required: helpers.withMessage('Confirmar la contraseña es requerido', required),
        sameAsPassword: helpers.withMessage(
          'Las contraseñas deben coincidir',
          (value) => value === this.password,
        ),
      },
    };
  },
  data() {
    return {
      numDocumento: '',
      idPaso: 1,
      codigoIdentidad: '',
      password: '',
      confirmPassword: '',
      submitted: false,
      showModal: false,
      showModalError: false,
    };
  },
  computed: {
    ...mapState(['loading']),
    ...mapState(['consultaUsuario']),
    tipoEstado() {
      return this.$store.state.consultaUsuario.estado;
    },
    correo() {
      return this.$store.state.consultaUsuario.email;
    },
  },
  methods: {
    async recuperarClave() {
      const body = {
        username: this.numDocumento,
        tipo_usuario: '1',
        tipo_documento: '03',
        skip_password: '1',
        usuario_interno: '0',
      };
      try {
        this.$store.state.loading = true;
        const response = await this.$store.dispatch('updateUsuario', body);
        if (response.status === 200 || response.status === 208) {
          this.$store.state.loading = false;
          this.respuesta = response.data;
          this.idPaso = 2;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        this.$store.state.loading = false;
        this.showModalError = true;
        this.respuesta = error.response ? error.response.data : 'Error de red';
      }
    },
    async enviarVerificacion() {
      const body = {
        tipo: '2',
      };
      try {
        this.$store.state.loading = true;
        const response = await ApiService.postVerificacion(body);
        if (response.status === 200 || response.status === 208) {
          this.$store.state.loading = false;
          if (this.tipoEstado === '1') {
            this.idPaso = 3;
          } else {
            // confirmar
            this.idPaso = 3;
          }
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        this.respuesta = error.response ? error.response.data : 'Error de red';
      }
    },
    async validaVerificacion() {
      const body = {
        codigo: this.codigoIdentidad,
      };
      try {
        this.$store.state.loading = true;
        const response = await ApiService.postValidaVerificacion(body);
        if (response.status === 200 || response.status === 208) {
          this.$store.state.loading = false;
          // this.$router.push('/');
          this.idPaso = 4;
        } else {
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      } catch (error) {
        // this.respuesta = error.response ? error.response.data : 'Error de red';
        if (error.response) {
          // El servidor respondió con un código de estado fuera del rango 2xx
          if (error.response.status === 400) {
            this.$store.state.loading = false;
            this.showModalError = true;
            // Aquí puedes manejar específicamente el error 400
            // Por ejemplo, mostrar los errores de validación al usuario
            if (error.response.data.errors) {
              this.errores = error.response.data.errors;
            }
          }
        } else if (error.request) {
          // La solicitud fue hecha pero no se recibió respuesta
          this.$store.state.loading = false;
          this.showModalError = true;
        } else {
          // Algo sucedió en la configuración de la solicitud que provocó un error
          this.$store.state.loading = false;
          this.showModalError = true;
        }
      }
    },
    async actualizarContrasena() {
      this.submitted = true;
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        const body = {
          username: this.$store.state.consultaUsuario.username,
          tipo_usuario: '1',
          usuario_interno: '0',
          reset_password: '1',
          password: this.password,
        };
        try {
          this.$store.state.loading = true;
          const response = await ApiService.postActualizarClave(body);
          if (response.status === 200 || response.status === 208) {
            this.$store.state.loading = false;
            this.showModal = true;
          } else {
            this.$store.state.loading = false;
            this.showModalError = true;
          }
        } catch (error) {
          // this.respuesta = error.response ? error.response.data : 'Error de red';
          if (error.response) {
            // El servidor respondió con un código de estado fuera del rango 2xx
            if (error.response.status === 400) {
              this.$store.state.loading = false;
              this.showModalError = true;
              // Aquí puedes manejar específicamente el error 400
              // Por ejemplo, mostrar los errores de validación al usuario
              if (error.response.data.errors) {
                this.$store.state.loading = false;
                this.showModalError = true;
                this.errores = error.response.data.errors;
              }
            }
          } else if (error.request) {
            // La solicitud fue hecha pero no se recibió respuesta
            this.$store.state.loading = false;
            this.showModalError = true;
          } else {
            // Algo sucedió en la configuración de la solicitud que provocó un error
            this.$store.state.loading = false;
            this.showModalError = true;
          }
        }
      } else {
        // Usuario inválido
      }
    },
    handleClose() {
      this.showModal = false;
    },
    handleCloseError() {
      this.showModalError = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
