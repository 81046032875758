<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable max-len -->
<template>
  <div
    id="content-wrapper"
    class="d-flex flex-column">
    <div
      v-if="loading"
      class="invex-loader">
      <div class="invex-loader_spinner" />
    </div>
    <div id="content">
      <header-component />
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="wrap-login">
              <div class="box-login bg-gray-light radius">
                <div class="circle">
                  <img src="@/assets/escudo.png">
                </div>
                <p class="d-none">
                  Entorno actual: {{ environment }}
                </p>
                <p class="d-none">
                  <code>consultaUsuario {{ consultaUsuario }}</code>
                </p>
                <div class="title mb-4">
                  Sistema de Gestión de Consultas de Planilla Electrónica
                </div>
                <div
                  class="box-form-login box-clave">
                  <div class="mb-3">
                    <div class="input-group auth-pass-inputgroup">
                      <input
                        v-model="password"
                        :type="passwordVisible ? 'text' : 'password'"
                        class="form-control"
                        placeholder="Ingrese Contraseña"
                        :class="{'is-invalid': submitted && v$.password.$error }"
                        aria-label="Password"
                        aria-describedby="password-addon">
                      <button
                        id="password-addon"
                        class="btn btn-light "
                        type="button"
                        @click="togglePasswordVisibility">
                        <i :class="passwordVisible ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline'" />
                      </button>
                    </div>
                    <div
                      v-if="submitted && v$.password.$error"
                      class="invalid-feedback">
                      <span v-if="v$.password.$error">{{ v$.password.$errors[0].$message }}</span>
                    </div>
                  </div>
                  <div class="form-group">
                    <button
                      type="button"
                      class="btn btn-block btn-warning"
                      @click="validaContrasena">
                      Ingresar
                    </button>
                  </div>
                  <div :class="['usuario-nuevo', { 'd-none': environment === 'production' }]" @click="irConsulta">
                    Consulta
                  </div>
                  <div :class="['auditoria-panel', { 'd-none': environment === 'production' }]" @click="irAuditoria">
                    Auditoria
                  </div>
                  <div :class="['dashboard', { 'd-none': environment === 'production' }]" @click="irPanel">
                    Panel
                  </div>
                </div>
                <div class="text-center">
                  <router-link class="text-muted" :to="{ name: 'recupera-clave' }">
                    <i class="mdi mdi-lock me-1" />
                    ¿Olvidó su contraseña?
                  </router-link>
                </div>
                <div class="box-descargas mt-4">
                  <a
                    href="#"
                    class="btn btn-danger btn-icon-split">
                    <span class="text">Descargar solicitud de acceso</span>
                  </a>
                  <a
                    href="#"
                    class="btn btn-danger btn-icon-split">
                    <span class="text">Descargar manual de acceso</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-component />
    <modal-error
      v-if="showModalError"
      :open="showModalError"
      @close="handleCloseError" />
    <modal-custom
      v-if="modalCustom.open"
      :open="modalCustom.open"
      :type="modalCustom.type"
      :title="modalCustom.title"
      :message="modalCustom.message"
      @close="handleCloseModal" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
// eslint-disable-next-line import/no-unresolved, import/extensions
import { required, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/no-unresolved, import/extensions
import ApiService from '@/apiservices';
// eslint-disable-next-line import/no-unresolved
import HeaderComponent from '@/components/HeaderComponent.vue';
// eslint-disable-next-line import/no-unresolved
import FooterComponent from '@/components/FooterComponent.vue';
// eslint-disable-next-line import/no-unresolved
import ModalError from '@/components/ModalError.vue';
// eslint-disable-next-line import/no-unresolved
import ModalCustom from '@/components/ModalCustom.vue';

export default {
  name: 'LoginClaveView',
  components: {
    HeaderComponent,
    FooterComponent,
    ModalError,
    ModalCustom,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    password: {
      required: helpers.withMessage('Password es requerido', required),
    },
  },
  data() {
    return {
      environment: process.env.VUE_APP_ENVIRONMENT,
      password: '',
      passwordVisible: false,
      showModalError: false,
      submitted: false,
      modalCustom: {
        open: false,
        title: '',
        message: '',
        type: 'success',
        customHtml: '',
      },
    };
  },
  computed: {
    ...mapState(['dobleFactor', 'loading']),
    ...mapState(['consultaUsuario']),
    tipoEstado() {
      return this.$store.state.consultaUsuario.estado;
    },
    correo() {
      return this.$store.state.consultaUsuario.email;
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    async validaContrasena() {
      this.submitted = true;
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        const body = {
          username: this.$store.state.consultaUsuario.username,
          tipo_usuario: '1',
          password: this.password,
          usuario_interno: '0',
          tipo_documento: '03',
        };
        try {
          this.$store.state.loading = true;
          // const response = await ApiService.postIniciarSesion(body);
          const response = await this.$store.dispatch('updateUsuario', body);
          if (response.status === 200 || response.status === 208) {
            this.$store.state.loading = false;
            this.respuesta = response.data;
            this.enviarVerificacion();
          } else {
            this.$store.state.loading = false;
            // this.showModalError = true;
            this.modalCustom.open = true;
            this.modalCustom.type = 'error';
            this.modalCustom.title = 'Error en su solicitud';
            this.modalCustom.message = '<p>No se ha podido conectar con el servidor.</p><p>Por favor intenta nuevamente.</p>';
          }
        } catch (error) {
          this.$store.state.loading = false;
          this.showModalError = true;
          this.respuesta = error.response ? error.response.data : 'Error de red';
        }
      } else {
        // Usuario inválido
      }
    },
    async enviarVerificacion() {
      const body = {
        tipo: '2',
      };
      try {
        this.$store.state.loading = true;
        const response = await ApiService.postVerificacion(body);
        if (response.status === 200 || response.status === 208) {
          this.$store.state.loading = false;
          if (this.tipoEstado === '1') {
            this.$router.push('/identidad');
          } else {
            this.$router.push('/actualiza');
          }
        } else {
          this.$store.state.loading = false;
          // this.showModalError = true;
          this.modalCustom.open = true;
          this.modalCustom.type = 'error';
          this.modalCustom.title = 'Error en su solicitud';
          this.modalCustom.message = '<p>No se ha podido conectar con el servidor.</p><p>Por favor intenta nuevamente.</p>';
        }
      } catch (error) {
        this.respuesta = error.response ? error.response.data : 'Error de red';
      }
    },
    vistaActualizarClave() {
      this.$router.push('/actualiza');
    },
    irPanel() {
      this.$router.push('/panel');
    },
    irConsulta() {
      this.$router.push('/consulta');
    },
    irAuditoria() {
      this.$router.push('/auditoria');
    },
    handleCloseError() {
      this.showModalError = false;
    },
    handleCloseModal() {
      this.modalCustom.open = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
