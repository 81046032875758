import Vue from 'vue';
import Router from 'vue-router';
import LogeoView from './views/account/LogeoView.vue';
import LoginClaveView from './views/account/LoginClaveView.vue';
import LoginIdentidadView from './views/account/LoginIdentidadView.vue';
import LoginActualizaView from './views/account/LoginActualizaView.vue';
import RecuperarClaveView from './views/account/RecuperarClaveView.vue';
import PanelView from './views/intranet/PanelView.vue';
import UsuarioView from './views/intranet/UsuarioView.vue';
import ConsultaView from './views/intranet/ConsultaView.vue';
import ConsultaViewCat from './views/intranet/ConsultaViewCat.vue';
import AuditoriaView from './views/auditoria/AuditoriaView.vue';
import AuditoriaUsers from './views/auditoria/AuditoriaUsers.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'login',
      component: LogeoView,
    },
    {
      path: '/clave',
      name: 'clave',
      component: LoginClaveView,
    },
    {
      path: '/identidad',
      name: 'identidad',
      component: LoginIdentidadView,
    },
    {
      path: '/actualiza',
      name: 'actualiza',
      component: LoginActualizaView,
    },
    {
      path: '/recupera-clave',
      name: 'recupera-clave',
      component: RecuperarClaveView,
    },
    {
      path: '/panel',
      name: 'panel',
      component: PanelView,
    },
    {
      path: '/usuario',
      name: 'usuario',
      component: UsuarioView,
    },
    {
      path: '/consulta',
      name: 'consulta',
      component: ConsultaView,
    },
    {
      path: '/consulta-cat',
      name: 'consultacat',
      component: ConsultaViewCat,
    },
    {
      path: '/auditoria',
      name: 'auditoria',
      component: AuditoriaView,
    },
    {
      path: '/auditoria-usuario',
      name: 'auditoria-usuario',
      component: AuditoriaUsers,
    },
  ],
});
