export default {
  loading: false,
  posts: [],
  dobleFactor: 'vistaContrasena',
  consultaUsuario: null,
  operacionSeleccionada: 'SPOT',
  usuarioSeleccionado: [],
  estadoEditar: false,
  tokenPayload: null,
};
