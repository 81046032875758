// eslint-disable-next-line import/prefer-default-export
export const myData = [{
  usuario: 'FMARTINEZ',
  tipo_categoria: 'Cuarta',
  fecha_de_pago: '03/08/2015',
  fecha_de_emision: '30/08/2015',
  tipo_de_contrato_o_comprobante: 'RECIBO POR HONORARIOS',
  numero_de_contrato_o_comprobante: 'E12-000423',
  ocupacion: '',
  categoria_ocupacional: '',
  situacion_especial: '',
  periodo_tributario: '202401',
  periodo_inicio: '03/08/2015',
  periodo_fin: '31/09/2015',
  razon_social: 'ASOCIACION DE BANCOS DEL PERU',
  ruc: '20100123330',
  ingreso: [{
    concepto: '000 - RECIBO POR HONORARIOS',
    monto_de_pago: 3106.67,
  }],
}];
