<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <div class="navbar-brand-box">
          <a
            href="/"
            class="logo logo-dark">
            <span class="logo-sm">
              <img
                src="@/assets/images/logo.svg"
                alt=""
                height="22">
            </span>
            <span class="logo-lg">
              <img
                src="@/assets/images/logo.svg"
                alt=""
                height="35">
            </span>
          </a>

          <a
            href="/"
            class="logo logo-light">
            <span class="logo-sm">
              <img
                src="@/assets/logo-movil.png"
                alt=""
                height="22">
            </span>
            <span class="logo-lg">
              <img
                src="@/assets/logo.png"
                alt=""
                height="35">
            </span>
          </a>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item text-white">
          <i
            class="fa fa-fw fa-bars"
            @click="toggleMenu" />
        </button>
      </div>
      <div class="d-flex">
        <h4 class="d-none d-md-block text-white m-0 ">
          <span v-if="$route.name === 'consulta' || $route.name === 'consulta-cat'">
            {{ nombreInstitucion }}
          </span>
          <span v-else>
            Ministerio de Trabajo y Promoción del Empleo
          </span>
        </h4>
      </div>
      <div class="d-flex">
        <div class="dropdown d-inline-block">
          <button
            id="page-header-user-dropdown"
            type="button"
            class="btn header-item waves-effect"
            aria-haspopup="true"
            :aria-expanded="isDropdownOpen"
            @click="toggleDropdown">
            <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/usuario.png"
              alt="Header Avatar">
            <span
              key="t-henry"
              class="d-none d-xl-inline-block ms-1 text-white">{{ mostraNombre }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </button>
          <div
            class="dropdown-menu dropdown-menu-end"
            :class="{ 'show': isDropdownOpen }">
            <a
              class="dropdown-item"
              href="#"><i class="bx bx-user font-size-16 align-middle me-1" /> <span key="t-profile">Perfil</span></a>
            <div class="dropdown-divider" />
            <a
              class="dropdown-item text-danger"
              href="#"
              @click="cerrarSesion">
              <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span key="t-logout">Salir</span></a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'HeaderMain',
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  computed: {
    ...mapState(['loading', 'consultaUsuario, tokenPayload']),
    mostraNombre() {
      const { consultaUsuario } = this.$store.state;
      const userData = JSON.parse(localStorage.getItem('user_data'));
      if (consultaUsuario && consultaUsuario.nombres) {
        return consultaUsuario.nombres;
      }
      if (userData && userData.username) {
        return userData.username;
      }
      return 'Usuario';
    },
    nombreInstitucion() {
      const { consultaUsuario } = this.$store.state;
      if (consultaUsuario && consultaUsuario.nombre_institucion) {
        return consultaUsuario.nombre_institucion;
      }
      return 'PODER JUDICIAL';
    },
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    toggleMenu() {
      document.body.classList.toggle('sidebar-enable');
      if (window.innerWidth >= 992) {
        document.body.classList.toggle('vertical-collpsed');
      } else {
        document.body.classList.remove('vertical-collpsed');
      }
    },
    cerrarSesion() {
      localStorage.clear();
      this.$store.dispatch('saveTokenPayload', null);
      this.$router.push('/');
    },
  },
};
</script>

  <style scoped>
  </style>
