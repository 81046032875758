import axios from 'axios';

const externalApiBase = process.env.VUE_APP_API_URL;

const apiClient = axios.create({
  baseURL: externalApiBase,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor de solicitud
apiClient.interceptors.request.use(
  (config) => {
    // Añadir el encabezado de autorización a cada solicitud
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = process.env.VUE_APP_TOKEN_PUBLICO;
    return config;
  },
  (error) =>
    // Manejar errores de solicitud
    // eslint-disable-next-line implicit-arrow-linebreak
    Promise.reject(error),

);

// Interceptor de respuesta
apiClient.interceptors.response.use(
  (response) =>
    // Aquí puedes procesar la respuesta si es necesario
    // eslint-disable-next-line implicit-arrow-linebreak
    response,
  (error) => {
    // Manejar errores de respuesta
    if (error.response) {
      /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
      console.error('Error de respuesta:', error.response.status, error.response.data);
    } else if (error.request) {
      console.error('Error de solicitud:', error.request);
    } else {
      console.error('Error:', error.message);
    }
    return Promise.reject(error);
  },
);

export default apiClient;
