<template>
  <div class="vertical-menu">
    <div class="h-100">
      <div id="sidebar-menu">
        <div v-if="$route.name === 'consulta' || $route.name === 'consultacat'">
          <ul
            id="side-menu"
            class="metismenu list-unstyled">
            <li
              key="t-menu"
              class="menu-title">
              Menu
            </li>
            <li>
              <a
                href="javascript: void(0);"
                class="has-arrow waves-effect">
                <i class="bx bx-home-circle" />
                <span key="t-dashboards">Categorías</span>
              </a>
              <ul
                class="sub-menu"
                aria-expanded="false">
                <li>
                  <a
                    key="t-default"
                    href="#"
                    :class="{ active: $route.path === '/consulta-cat' }"
                    @click="irCategoria4">4ta Categoria</a>
                </li>
                <li>
                  <a
                    key="t-default"
                    href="#"
                    :class="{ active: $route.path === '/consulta' }"
                    @click="irCategoria5">5ta Categoria</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div v-else-if="$route.name === 'auditoria' || $route.name === 'auditoria-usuario'">
          <ul
            id="side-menu"
            class="metismenu list-unstyled">
            <li
              key="t-menu"
              class="menu-title">
              Menu
            </li>
            <li>
              <a
                href="javascript: void(0);"
                class="has-arrow waves-effect">
                <i class="bx bx-home-circle" />
                <span key="t-dashboards">Categorías</span>
              </a>
              <ul
                class="sub-menu"
                aria-expanded="false">
                <li>
                  <a
                    key="t-default"
                    href="#"
                    :class="{ active: $route.path === '/auditoria' }"
                    @click="irAuditoria">Auditoria</a>
                </li>
                <li>
                  <a
                    key="t-default"
                    href="#"
                    :class="{ active: $route.path === '/auditoria-usuario' }"
                    @click="irAuditoriaUsuarios">Usuarios Creados</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div v-else>
          <ul
            id="side-menu"
            class="metismenu list-unstyled">
            <li
              key="t-menu"
              class="menu-title">
              Menu
            </li>
            <li>
              <a
                href="javascript: void(0);"
                class="has-arrow waves-effect">
                <i class="bx bx-home-circle" />
                <span key="t-dashboards">Usuarios</span>
              </a>
              <ul
                class="sub-menu"
                aria-expanded="false">
                <li>
                  <a
                    key="t-default"
                    href="#"
                    :class="{ active: $route.path === '/panel' }"
                    @click.prevent="irPanel">Listado de usuario</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SidebarMain',
  computed: {
  },
  methods: {
    irPanel() {
      this.$router.push('/panel');
    },
    irCategoria4() {
      this.$router.push('/consulta-cat');
    },
    irCategoria5() {
      this.$router.push('/consulta');
    },
    irAuditoria() {
      this.$router.push('/auditoria');
    },
    irAuditoriaUsuarios() {
      this.$router.push('/auditoria-usuario');
    },
  },
};
</script>

  <style scoped>
  </style>
