import ApiClient from './clients/ApiClient';

export default {
  // Users
  getPost() {
    return ApiClient.get('/users/1/posts');
  },
  postIniciarSesion(body) {
    // return InvexClient.post(`${prefixGlobal}/api-spsdb-fx/ConsultaIntitucionesBancarias`, body);
    return ApiClient.post('/seguridad/api/acceso/', body);
  },
  postVerificacion(body) {
    // return InvexClient.post(`${prefixGlobal}/api-spsdb-fx/ConsultaIntitucionesBancarias`, body);
    return ApiClient.post('/seguridad/api/enviar-verificacion/', body);
  },
};
