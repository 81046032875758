<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable max-len -->
<template>
  <div
    id="content-wrapper"
    class="d-flex flex-column">
    <div
      v-if="loading"
      class="invex-loader">
      <div class="invex-loader_spinner" />
    </div>
    <div id="content">
      <header-component />
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="wrap-login">
              <div class="box-login bg-gray-light radius">
                <div class="circle">
                  <img src="@/assets/escudo.png">
                </div>
                <div class="title mb-4">
                  Sistema de Gestión de Consultas de Planilla Electrónica
                </div>
                <div class="box-form-login box-actualiza">
                  <div class="mb-3">
                    <div class="box-texto-correo">
                      <h4>Actualizar Contraseña</h4>
                      <p>Ingrese su nueva contraseña</p>
                      <p class="text-danger">
                        La nueva contraseña debe ser de 12 dígitos, debe tener al menos una letra minúscula,
                        al menos una letra mayúscula, al menos un número, al menos un carácter especial
                      </p>
                    </div>
                    <div class="group-input">
                      <div class="mb-3">
                        <label class="form-label">Ingrese Contraseña</label>
                        <input
                          v-model="password"
                          type="password"
                          class="form-control"
                          placeholder="Ingrese Contraseña"
                          :class="{'is-invalid': submitted && v$.password.$error }">
                        <div
                          v-if="submitted && v$.password.$error"
                          class="invalid-feedback">
                          <span v-if="v$.password.$error">{{ v$.password.$errors[0].$message }}</span>
                        </div>
                      </div>
                      <div>
                        <label class="form-label">Repetir Contraseña</label>
                        <input
                          v-model="confirmPassword"
                          type="password"
                          class="form-control"
                          placeholder="Ingrese Contraseña"
                          :class="{'is-invalid': submitted && v$.confirmPassword.$error }">
                        <div
                          v-if="submitted && v$.confirmPassword.$error"
                          class="invalid-feedback">
                          <span v-if="v$.confirmPassword.$error">{{ v$.confirmPassword.$errors[0].$message }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <button
                      type="button"
                      class="btn btn-block btn-warning"
                      @click="actualizarContrasena">
                      Ingresar
                    </button>
                  </div>
                </div>
                <div class="text-center">
                  <router-link class="text-muted" :to="{ name: 'recupera-clave' }">
                    <i class="mdi mdi-lock me-1" />
                    ¿Olvidó su contraseña?
                  </router-link>
                </div>
                <div class="box-descargas mt-4">
                  <a
                    href="#"
                    class="btn btn-danger btn-icon-split">
                    <span class="text">Descargar solicitud de acceso</span>
                  </a>
                  <a
                    href="#"
                    class="btn btn-danger btn-icon-split">
                    <span class="text">Descargar manual de acceso</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-component />
    <modal-error
      v-if="showModalError"
      :open="showModalError"
      @close="handleCloseError" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
// eslint-disable-next-line import/no-unresolved, import/extensions
import { required, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/extensions, import/no-unresolved
import ApiService from '@/apiservices';
// eslint-disable-next-line import/no-unresolved
import HeaderComponent from '@/components/HeaderComponent.vue';
// eslint-disable-next-line import/no-unresolved
import FooterComponent from '@/components/FooterComponent.vue';
// eslint-disable-next-line import/no-unresolved
import ModalError from '@/components/ModalError.vue';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{12,}$/;

const strongPassword = helpers.withMessage(
  'La contraseña debe tener al menos 12 caracteres, incluir al menos una minúscula, una mayúscula, un número y un carácter especial',
  (value) => passwordRegex.test(value),
);

export default {
  name: 'LoginActualizaView',
  components: {
    HeaderComponent,
    FooterComponent,
    ModalError,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      password: {
        required: helpers.withMessage('La contraseña es requerida', required),
        strongPassword,
      },
      confirmPassword: {
        required: helpers.withMessage('Confirmar la contraseña es requerido', required),
        sameAsPassword: helpers.withMessage(
          'Las contraseñas deben coincidir',
          (value) => value === this.password,
        ),
      },
    };
  },
  data() {
    return {
      passwordVisible: false,
      showModalError: false,
      password: '',
      confirmPassword: '',
      submitted: false,
    };
  },
  computed: {
    ...mapState(['dobleFactor', 'loading']),
    ...mapState(['consultaUsuario']),
    tipoEstado() {
      return this.$store.state.consultaUsuario.estado;
    },
    correo() {
      return this.$store.state.consultaUsuario.email;
    },
  },
  methods: {
    async actualizarContrasena() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$invalid) {
        // Usuario inválido
        // eslint-disable-next-line no-alert
      } else {
        const body = {
          username: this.$store.state.consultaUsuario.username,
          tipo_usuario: '1',
          usuario_interno: '0',
          reset_password: '1',
          password: this.password,
        };
        try {
          this.$store.state.loading = true;
          const response = await ApiService.postActualizarClave(body);
          if (response.status === 200 || response.status === 208) {
            this.$store.state.loading = false;
            this.$router.push('/clave');
          } else {
            this.$store.state.loading = false;
            this.showModalError = true;
          }
        } catch (error) {
          // this.respuesta = error.response ? error.response.data : 'Error de red';
          if (error.response) {
            // El servidor respondió con un código de estado fuera del rango 2xx
            if (error.response.status === 400) {
              this.$store.state.loading = false;
              this.showModalError = true;
              // Aquí puedes manejar específicamente el error 400
              // Por ejemplo, mostrar los errores de validación al usuario
              if (error.response.data.errors) {
                this.$store.state.loading = false;
                this.showModalError = true;
                this.errores = error.response.data.errors;
              }
            }
          } else if (error.request) {
            // La solicitud fue hecha pero no se recibió respuesta
            this.$store.state.loading = false;
            this.showModalError = true;
          } else {
            // Algo sucedió en la configuración de la solicitud que provocó un error
            this.$store.state.loading = false;
            this.showModalError = true;
          }
        }
      }
    },
    handleCloseError() {
      this.showModalError = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
