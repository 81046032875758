import Repository from '../repositories/RepositoryFactory';

const PostRepository = Repository.get('posts');
const ApiRepository = Repository.get('api');

export default {
  async getPosts({ commit }) {
    commit('setLoading', true);
    try {
      const response = await PostRepository.getTop(3);
      const posts = response.entries.map((entry) => ({
        description: entry.fields.description,
        title: entry.fields.title,
        slug: entry.fields.slug,
        image: entry.fields.covers ? entry.fields.covers[0].url : '',
        imageAlt: entry.fields.covers ? entry.fields.covers[0].alt_text : '',
      }));
      commit('updatePosts', posts);
      return response;
    } catch (error) {
      return error;
    } finally {
      commit('setLoading', false);
    }
  },
  async updateDobleFactor({ commit }, payload) {
    commit('updateDobleFactor', payload);
  },

  // Servicio Usuario
  async updateUsuario({ commit }, datos) {
    commit('setLoading', true);
    try {
      const response = await ApiRepository.postIniciarSesion(datos);
      const info = response.data;
      commit('updateUsuario', info);
      return response;
    } catch (error) {
      return error;
    } finally {
      commit('setLoading', false);
    }
  },
  updateOperacionSeleccionada({ commit }, operacion) {
    commit('setOperacionSeleccionada', operacion);
  },
  updateUsuarioSeleccionado({ commit }, payload) {
    commit('setUsuarioSeleccionado', payload);
  },
  updateEstadoEditar({ commit }, payload) {
    commit('setEstadoEditar', payload);
  },
  saveTokenPayload({ commit }, payload) {
    commit('setTokenPayload', payload);
  },
};
