<!-- eslint-disable max-len -->
<template>
  <div
    id="app"
    class="wrapper">
    <router-view />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'App',
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapState(['posts']),
  },
  mounted() {
    document.body.setAttribute('data-sidebar', 'colored');
  },
  created() {
    // this.getPosts();
  },
  methods: {
    ...mapActions(['getPosts']),
    toggleMenu() {
      document.body.classList.toggle('sidebar-enable');
      if (window.innerWidth >= 992) {
        document.body.classList.toggle('vertical-collpsed');
      } else {
        document.body.classList.remove('vertical-collpsed');
      }
    },
  },
};
</script>

<style lang="scss">
img {
  max-width: 100%;
}
</style>
