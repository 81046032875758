<!-- src/components/HeaderComponent.vue -->
<template>
  <footer class="footer-login bg-gray fixed-bottom text-white">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4 text-center my-auto">
          <div>Ministerio de Trabajo y Promoción del Empleo</div>
          <div>©copyright - {{ new Date().getFullYear() }}</div>
        </div>
        <div class="col-12 col-md-4 text-center my-auto">
          <div>Sistema de Gestión de Consultas de Planilla Electrónica</div>
        </div>
        <div class="col-12 col-md-4 my-auto text-md-left box-footer">
          <div class="texto-footer">
            <p>
              Si presenta algún incidente en el sistema puede utilizar los medios
              de contacto:
            </p>
            <div>Teléfono 016306000 anexo 9009</div>
            <div>correo electrónico mds@trabajo.gob.pe</div>
          </div>
          <div class="box-img-footer">
            <img src="@/assets/escudo.png">
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
};
</script>

  <style scoped>
  /* Estilos para el header */
  .app-header {
    background-color: #333;
    color: #fff;
    padding: 1rem;
  }
  .app-header nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  .app-header nav ul li {
    margin-right: 1rem;
  }
  .app-header nav ul li a {
    color: #fff;
    text-decoration: none;
  }
  </style>
